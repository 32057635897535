import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { InstructionStatus } from 'src/models/pci';

@Pipe({ name: 'calendar_entry_color' })
export class CalendarEntryColorPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === InstructionStatus.requested,
        _ => '#ea5419',
      )
      .on(
        v => v === InstructionStatus.confirmed || v === InstructionStatus.meeting_performed,
        _ => '#00a0e8',
      )
      .on(
        v =>
          v === InstructionStatus.declined ||
          v === InstructionStatus.incomplete ||
          v === InstructionStatus.cancelled ||
          v === InstructionStatus.cancelled_by_patient ||
          v === InstructionStatus.cancelled_by_pharmacist ||
          v === InstructionStatus.withdrawn,
        _ => '#aea5a0',
      )
      .on(
        v => v === InstructionStatus.completed || v === InstructionStatus.meeting_completed,
        _ => '#2660ac',
      )
      .otherwise(_ => '#aea5a0');
  }
}
