<div class="hamburger-menu" (click)="openSidenav()" *ngIf="displayHamburger$ | async" fxLayout="column">
  <mat-icon>menu</mat-icon>
</div>
<div>
  <mat-drawer-container
    [class]="(this.loginTypeAndId$ | async) ? 'sidenav-container' : 'logo-background'"
    [hasBackdrop]="sidenavBackdrop$ | async"
    (backdropClick)="onBackdropClick()"
    autosize
  >
    <mat-drawer
      #sidenav
      class="sidenav hidden-on-print"
      [mode]="(sidenavMode$ | async)!"
      [opened]="sidenavOpen$ | async"
    >
      <app-sidenav
        [screenWidth]="(screenWidth$ | async)!"
        [collapsed]="(sidenavCollapse$ | async)!"
        (toggle)="toggleSidenav()"
        (menuSelect)="onMenuSelect()"
      ></app-sidenav>
    </mat-drawer>

    <mat-drawer-content class="sidenav-content">
      <div>
        <section class="container">
          <app-header [title]="title"></app-header>
          <main>
            <loading-spinner [loading]="loading$ | async"></loading-spinner>
            <router-outlet *ngIf="!(loading$ | async)"></router-outlet>
          </main>
          <app-footer></app-footer>
        </section>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
