import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { LinkCardContent } from 'src/app/parts/card/card.component';

@Component({
  selector: 'pharmacy-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  linkContents: LinkCardContent[] = [
    {
      href: '/pharmacy/pharmacists',
      title: ['薬剤師一覧'],
      icon: 'people',
    },
    {
      href: '/pharmacy/reservation-slots',
      title: ['オンライン服薬指導', '予約可能枠'],
      icon: 'event_note',
    },
    {
      href: '/pharmacy/pci-templates',
      title: ['服薬指導', 'フォーマット一覧'],
      icon: 'question_answer',
    },
    {
      href: '/pharmacy/payment_setting',
      title: ['決済機能設定'],
      text: `支払情報の設定`,
      icon: 'payments',
    },
    {
      href: '/pharmacy/medical-questionnaire-template',
      title: ['問診票', 'テンプレート一覧'],
      icon: 'quiz',
    },
    {
      href: '/pharmacy/pcf-templates',
      title: ['服薬フォロー', 'テンプレート一覧'],
      icon: 'article',
    },
    {
      href: '/pharmacy/profile',
      title: ['薬局情報編集'],
      icon: 'manage_accounts',
    },
    {
      href: '/pharmacy/change-password',
      title: ['パスワード変更'],
      icon: 'lock',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
