import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { ChatbotStatus } from 'src/models/chatbot';

@Pipe({ name: 'chatbot_status' })
export class ChatbotStatusPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === ChatbotStatus.planned,
        _ => '計画済み',
      )
      .on(
        v => v === ChatbotStatus.started,
        _ => '開始済み',
      )
      .on(
        v => v === ChatbotStatus.finished,
        _ => '終了済み',
      )
      .on(
        v => v === ChatbotStatus.cancelled,
        _ => 'キャンセル',
      )
      .on(
        v => v === ChatbotStatus.read,
        _ => '既読',
      )
      .on(
        v => v === ChatbotStatus.notified,
        _ => '通知済み',
      )
      .otherwise(v => '不明');
  }
}
