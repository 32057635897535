import { format, isToday } from 'date-fns';
import { when } from 'src/app/modules/when';
import { IPatientInfo, IPharmacist } from '.';
import { IEntityBase } from './entity-base';
import { Payment } from './payment';
import { IQAT, QuestionType } from './qa-template';
export interface IPCI extends IEntityBase {
  id: string;
  is_open?: number;
  is_meeting_started?: boolean;
  patient_account_id?: string;
  patient_info_id?: string;
  patient_info?: IPatientInfo;
  pharmacist?: IPharmacist;
  pharmacy_id?: string;
  time_frames?: TimeFrame[];
  status?: InstructionStatus;
  pharmacist_id?: string;
  pci_questions_template_id?: string;
  pci_questions?: IQAT[];
  pci_answers?: IQAT[];
  created_at?: number;
  updated_at?: number;
  contents?: TemplateAnswer[];
  delivery?: Delivery;
  delivery_method_created_at?: number;
  payment?: String;
}

export interface Delivery {
  method: DeliveryMethod;
  status: DeliveryStatus;
  memo_for_pharmacist: string;
  message_to_patient: string;
  shipped_at: number;
}

export interface DeliveryForPost {
  method: DeliveryMethod;
  memo_for_pharmacist: string;
  //message_to_patient: string;
}

export type DeliveryMethod = 'normal' | 'cash_on_delivery' | 'drop_by' | 'collect_on_delivery';
export type DeliveryStatus = 'unshipped' | 'shipped';
export class TemplateAnswer {
  answer: string[];
  private _checkboxes: {
    label: string;
    checked: boolean;
  }[] = [];
  constructor(public question: string, answer: string[] | undefined, public type: QuestionType) {
    answer = answer ?? [];
    if (type === QuestionType.checkbox) {
      this._checkboxes =
        answer.map(a => ({
          label: a,
          checked: false,
        })) ?? [];
      this.answer = [];
    } else {
      this.answer = [''];
    }
  }

  get checkboxes() {
    this.answer = this._checkboxes.filter(c => c.checked).map(c => c.label);
    return this._checkboxes;
  }
  set checkboxes(v) {
    this._checkboxes = v;
  }
}

export enum InstructionClass {
  pci = 'pci',
  pcf = 'pcf',
  cbf = 'cbf',
  other = 'other',
}

export enum DeliveryMethodEnum {
  normal = 'normal',
  collect_on_delivery = 'collect_on_delivery',
  drop_by = 'drop_by',
  cash_on_delivery = 'cash_on_delivery',
}

export enum InstructionStatus {
  requested = 'requested',
  withdrawn = 'withdrawn',
  confirmed = 'confirmed',
  cancelled_by_patient = 'cancelled_by_patient',
  cancelled_by_pharmacist = 'cancelled_by_pharmacist',
  declined = 'declined',
  meeting_performed = 'meeting_performed',
  meeting_completed = 'meeting_completed',
  completed = 'completed',
  incomplete = 'incomplete',
  cancelled = 'cancelled',
}

export type TimeFrame = {
  start_time: number;
  end_time: number;
  status?: string;
};
export class Pci {
  id: string;
  is_open?: number;
  is_meeting_started?: boolean;
  patient_account_id?: string;
  patient_info_id?: string;
  patient_info?: IPatientInfo;
  pharmacist?: IPharmacist;
  pharmacy_id?: string;
  time_frames: TimeFrame[];
  status?: InstructionStatus;
  pharmacist_id?: string;
  contents: TemplateAnswer[];
  pci_questions_template_id?: string;
  pci_questions?: IQAT[];
  pci_answers?: IQAT[];
  created_at?: number;
  updated_at?: number;

  constructor(pci: IPCI) {
    this.id = pci.id;
    this.is_open = pci.is_open;
    this.is_meeting_started = pci.is_meeting_started;
    this.patient_account_id = pci.patient_account_id;
    this.patient_info_id = pci.patient_info_id;
    this.patient_info = pci.patient_info;
    this.pharmacist = pci.pharmacist;
    this.pharmacy_id = pci.pharmacy_id;
    this.time_frames = pci.time_frames ?? [];
    this.status = pci.status;
    this.pharmacist_id = pci.pharmacist_id;
    this.contents = pci.contents ?? [];
    this.pci_questions_template_id = pci.pci_questions_template_id;
    this.pci_questions = pci.pci_questions;
    this.pci_answers = pci.pci_answers;
    this.created_at = pci.created_at;
    this.updated_at = pci.updated_at;

    this.sortTimeFrames();
  }

  get startTimeOfFirstTimeFrame(): number | undefined {
    return this.time_frames[0]?.start_time;
  }

  get timeFrameOfTodayAndAfterCurrentTime(): TimeFrame | undefined {
    if (this.status !== 'confirmed') {
      return;
    }
    return this.time_frames
      ?.filter(t => t.status === InstructionStatus.confirmed)
      .find(t => {
        const startTime = new Date(t.start_time);
        return isToday(startTime) && startTime.getHours() >= new Date().getHours();
      });
  }

  private sortTimeFrames() {
    this.time_frames.sort((a, b) => a.start_time - b.start_time).sort((a, b) => (b.status === 'confirmed' ? 1 : -1));
  }
}

export class TimeFrameForDisplay {
  public id: string;
  public startDateTime: Date;
  public endDateTime: Date;
  public patientName: string;
  public patientNameKana: string;
  public status?: InstructionStatus;

  constructor(public original: IPCI, public readonly timeFrameIndex: number) {
    this.id = original.id;
    const timeFrame = original ? (original.time_frames ? original.time_frames[timeFrameIndex] : null) : null;
    this.startDateTime = new Date(timeFrame?.start_time ?? 0);
    this.endDateTime = new Date(timeFrame?.end_time ?? 0);
    if (original.status === InstructionStatus.confirmed) {
      this.status = when(timeFrame?.status)
        .on(
          s => s === 'confirmed',
          _ => InstructionStatus.confirmed,
        )
        .on(
          s => s === 'declined',
          _ => InstructionStatus.declined,
        )
        .otherwise(() => undefined);
    } else {
      this.status = original.status;
    }

    this.patientName = `${original.patient_info?.family_name ?? ''}${original.patient_info?.given_name ?? '-'}`;
    this.patientNameKana =
      (original.patient_info?.family_name_kana ?? '') + (original.patient_info?.given_name_kana ?? '-');
  }

  get contents(): TemplateAnswer[] {
    return this.original.contents ?? [];
  }
  set contents(v: TemplateAnswer[]) {
    this.original.contents = v;
  }

  get dateText(): string {
    try {
      return format(this.startDateTime, 'yyyy年MM月dd日(ccc)');
    } catch (error) {
      return 'Invalid Date';
    }
  }

  get timeText(): string {
    try {
      return format(this.startDateTime, 'HH:mm');
    } catch (error) {
      return 'Invalid time';
    }
  }
}
export class ReservationForList {
  public id: string;
  public is_meeting_started: boolean;
  public receptionHours: number;
  public patientInfoId: string;
  public patientName: string;
  public patientTel: string;
  public reservation: string;
  public status?: InstructionStatus;
  public pharmacistName: string;
  public pharmacistId: string;
  public pci_questions_template_id?: string;
  public pci_questions?: IQAT[];
  public pci_answers?: IQAT[];
  public patientAccountId: string;
  public delivery?: Delivery;
  public payment?: String;

  constructor(public original: IPCI, pharmacists: IPharmacist[]) {
    this.id = original.id;
    this.is_meeting_started = original.is_meeting_started ?? false;
    this.receptionHours = original.created_at ?? 0;

    this.reservation = when(original.status)
      .on(
        s => s === InstructionStatus.requested,
        _ => '予約確定待ち',
      )
      .otherwise(_ => {
        const res = original.time_frames?.find(r => r.status === 'confirmed');
        return res ? format(new Date(res.start_time), 'yyyy/MM/dd HH:mm') : '-';
      });
    this.status = original.status;

    this.patientInfoId = original.patient_info_id ?? '';
    this.patientName = `${original.patient_info?.family_name ?? ''}${original.patient_info?.given_name ?? '-'}`;
    const pharmacist = pharmacists.find(p => p.id === original.pharmacist_id);
    this.pharmacistId = pharmacist?.id ?? '';
    this.pharmacistName = `${pharmacist?.family_name ?? ''}${pharmacist?.given_name ?? '-'}`;

    this.patientTel = original.patient_info?.tel ?? '';
    this.pci_questions_template_id = original.pci_questions_template_id;
    this.pci_questions = original.pci_questions;
    this.pci_answers = original.pci_answers;
    this.delivery = original.delivery;
    this.payment = original.payment;

    this.patientAccountId = original.patient_account_id ?? '';
  }

  get contents(): TemplateAnswer[] {
    return this.original.contents ?? [];
  }
  set contents(v: TemplateAnswer[]) {
    this.original.contents = v;
  }
}
