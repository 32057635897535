<loading-spinner [loading]="loading"></loading-spinner>
<div class="root" *ngIf="!loading" fxLayout="column" fxLayoutGap="20px">
  <div class="head-part" fxLayout="row" fxLayoutGap="20px">
    <div class="select-time" fxFlex="50" fxFlex.lt-md="100" [style.borderColor]="noDateAlert ? '#f44336' : ''">
      <div class="large-font" fxLayout="row" fxLayoutGap="10px">
        <mat-icon fxFlexAlign="center">access_alarm</mat-icon>
        <div>送信時刻設定</div>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div fxLayout="row" fxLayoutGap="100px">
        <div fxLayout="column">
          <div fxLayout="row">
            <mat-form-field class="form-field date">
              <mat-label>日付を選択</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                [matDatepickerFilter]="dateFilter"
                (dateInput)="selectedDate = $event.value; noDateAlert = false"
              />
              <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>
            <button mat-stroked-button (click)="picker.open()"><mat-icon>event</mat-icon></button>
            <div fxFlexOffset="10px" fxFlexAlign="center">
              {{ selectedDate ? (selectedDate.unix() * 1000 | date: 'yyyy年MM月dd日') : '日付を選択してください' }}
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field class="form-field time">
              <mat-select [(ngModel)]="selectedHour">
                <mat-option *ngFor="let hour of selectableHour" [value]="hour">{{ hour }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div fxFlexAlign="center">時</div>
            <mat-form-field class="form-field time">
              <mat-select [(ngModel)]="selectedMin">
                <mat-option *ngFor="let minute of selectableMin" [value]="minute">{{
                  minute.toString().padStart(2, '0')
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div fxFlexAlign="center">分</div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-radio-group class="type-toggle" fxLayout="column" [(ngModel)]="repeat">
            <mat-radio-button [value]="'no'">繰り返しなし</mat-radio-button>
            <mat-radio-button [value]="'daily'">毎日送信</mat-radio-button>
            <mat-radio-button [value]="'weekly'">毎週送信</mat-radio-button>
            <mat-radio-button [value]="'monthly'">毎月送信</mat-radio-button>
          </mat-radio-group>
          <div *ngIf="repeat !== 'no'">
            <mat-label>繰り返し回数</mat-label>
            <input
              class="repeat-times"
              type="number"
              id="RepeatTimes"
              name="RepeatTimes"
              required
              min="1"
              max="30"
              [(ngModel)]="repeatTimes"
            />回
          </div>
        </div>
      </div>
      <div fxLayout="row-reverse" fxLayoutGap="10px">
        <loading-button
          mat-raised-button
          [loading]="buttonLoading"
          class="reservation-button"
          color="primary"
          [name]="'送信（予約）'"
          (click)="save()"
          icon="add_alarm"
        ></loading-button>
        <loading-button
          mat-raised-button
          [loading]="buttonLoading"
          color="primary"
          [name]="'送信（即時）'"
          (click)="send()"
          icon="send"
        ></loading-button>
      </div>
    </div>
    <app-patient-info-accordion fxFlex="50" fxFlex.lt-md="100" [patientInfo]="patientInfo"></app-patient-info-accordion>
  </div>
  <button class="round-button" mat-raised-button color="primary" (click)="createFollow()">
    <mat-icon>history</mat-icon>テンプレートや履歴から選択
  </button>
  <div class="follow-edit-part">
    <div
      class="question-container"
      cdkDropList
      #questionList
      (cdkDropListDropped)="dropQuestion($event)"
      fxLayout="column"
      fxLayoutGap="15px"
      fxFlexAlign="center"
    >
      <mat-card class="question-content" cdkDrag *ngFor="let question of questions; index as i" fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-icon fxFlexAlign="stretch" cdkDragHandle class="drag-handle">drag_indicator</mat-icon>
          <div fxLayout="row" fxLayoutGap="10px" fxFlex>
            <div fxLayout="column" fxLayoutAlign="space-evenly" class="type-and-require-select">
              <mat-checkbox [(ngModel)]="question.required">必須</mat-checkbox>
              <mat-radio-group class="type-toggle" fxLayout="column" [(ngModel)]="question.type">
                <mat-radio-button [value]="'radio'">1つ選択</mat-radio-button>
                <mat-radio-button [value]="'checkbox'">複数選択</mat-radio-button>
                <mat-radio-button [value]="'textbox'">自由記述</mat-radio-button>
              </mat-radio-group>
            </div>
            <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px">
              <mat-form-field
                class="form-field question"
                fxFlex.gt-sm="45"
                fxFlexAlign.gt-sm="stretch"
                fxLayout="column"
                fxLayoutAlign="center"
              >
                <mat-label>質問文を入力</mat-label>
                <textarea class="textarea question" matInput [(ngModel)]="question.question"></textarea>
              </mat-form-field>
              <div class="choice-area" fxFlex.gt-sm="55" *ngIf="question.type !== 'textbox'">
                <div class="choice-container" cdkDropList (cdkDropListDropped)="dropChoice(i, $event)">
                  <mat-card
                    class="choice-content"
                    fxLayout="row"
                    fxLayoutGap="10px"
                    cdkDrag
                    *ngFor="let choice of question.answers; index as j; trackBy: trackByIndex"
                  >
                    <div fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="center" class="choice-number-wrap">
                      <span class="choice-number">{{ j + 1 }}.</span>
                    </div>
                    <mat-form-field fxFlex>
                      <mat-label>選択肢名を入力</mat-label>
                      <input matInput type="text" [(ngModel)]="question.answers[j]" />
                    </mat-form-field>
                    <button
                      mat-icon-button
                      fxFlexAlign="center"
                      [disabled]="question.answers.length < 2"
                      (click)="question.answers.splice(j, 1)"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-card>
                </div>
                <button mat-stroked-button (click)="question.answers.push('')" class="add-choice">
                  <div fxLayout="row" fxLayoutGap="10px">
                    <mat-icon fxFlexAlign="center">add_circle_outline</mat-icon>
                    <div fxFlexAlign="center">選択肢を追加</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <button mat-icon-button fxFlexAlign="start" (click)="questions.splice(i, 1)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-card>
      <div *ngIf="questions.length === 0">質問がありません</div>
    </div>
  </div>
  <button mat-stroked-button fxFlexAlign="center" (click)="addQuestion()" class="add-question">
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-icon fxFlexAlign="center">add_circle_outline</mat-icon>
      <div fxFlexAlign="center">質問を追加</div>
    </div>
  </button>
</div>
