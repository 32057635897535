<header class="logo-background">
  <div class="left">
    <div class="pharmacy-and-pharmacist">
      <div *ngIf="pharmacy$ | async as pharmacy">
        <div>{{ pharmacy.company_name }} {{ pharmacy.store_name }}</div>
      </div>
      <div *ngIf="pharmacist$ | async as pharmacist">
        <div>{{ pharmacist.family_name }} {{ pharmacist.given_name }}</div>
      </div>
    </div>
    <button *ngIf="parentPass$ | async as parentPass" class="back-button" mat-raised-button (click)="back()">
      <mat-icon aria-hidden="false" aria-label="back icon">arrow_back_ios</mat-icon> 戻る
    </button>
  </div>
  <div class="center" [routerLink]="linkToTop$ | async">
    <div class="logo"><img class="img" src="/assets/ConnectOnline_logo.png" alt="logo" /></div>
    <h1 *ngIf="currentPath$ | async; aND: !title">{{ title }}</h1>
  </div>
  <div class="right">
    <div class="buttons-on-right">
      <button
        class="notification-button"
        *ngIf="pharmacist$ | async"
        matBadge="{{ notices.length }}"
        [matBadgeColor]="'warn'"
        [matBadgeHidden]="!notices.length"
        mat-stroked-button
        #overlayOrigin="cdkOverlayOrigin"
        cdk-overlay-origin
        (click)="openNotificationsOverlay()"
      >
        <mat-icon>announcement</mat-icon> 新着通知
      </button>
      <button
        class="logout-button round-button"
        mat-raised-button
        color="primary"
        *ngIf="showLogoutButton$ | async"
        (click)="logout()"
      >
        <mat-icon aria-hidden="false" aria-label="logout icon">logout</mat-icon> ログアウト
      </button>
    </div>
  </div>
</header>
