<div
  fxFlexAlign="center"
  class="top-menu-container"
  [fxLayout]="isSingle ? 'column' : 'row'"
  fxFlexAlign="space-around"
>
  <div class="grid-cell" *ngFor="let content of linkContents; index as i">
    <div
      class="pharmacy-top-menu-wrap"
      (mouseover)="onMouseOver(icon, buttonBackground)"
      (mouseleave)="onMouseLeave(icon, buttonBackground)"
      matRipple
      [routerLink]="content.href"
    >
      <div fxFlexAlign="center" class="pharmacy-top-menu" fxLayout="row">
        <div fxFlex="25" fxFlex.lt-md="0" fxFlexAlign="center" fxLayout="row"></div>
        <div fxFlex="75" fxFlex.lt-md="100" fxLayout="column" fxLayoutGap="10px" class="pharmacy-menu-title-wrapper">
          <div fxFlexAlign="center" fxLayout="column" fxLayoutAlign="center" class="pharmacy-menu-title">
            <div
              [style.font-size]="content.title.length > 1 ? '22px' : '27px'"
              [style.transform]="content.title.length > 1 ? 'translate(0,4px)' : ''"
            >
              {{ content.title[0] }}
            </div>
            <div *ngIf="content.title.length > 1" [style.font-size]="content.title.length > 1 ? '22px' : '27px'">
              {{ content.title[1] }}
            </div>
          </div>
        </div>
      </div>
      <div #buttonBackground class="pharmacy-top-menu-background" fxLayout="row">
        <div fxFlex="25" fxLayout="row" fxLayoutAlign="center" class="pharmacy-menu-icon-wrap">
          <mat-icon #icon fxFlexAlign="center">{{ content.icon }}</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
