import { Injectable } from '@angular/core';
import { IPharmacist } from 'src/models';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';
//import axios from 'axios';
import { environment } from 'src/environments/environment';
import axios, { AxiosRequestConfig } from 'axios';

@Injectable({
  providedIn: 'root',
})
export class PharmacistService extends ServiceBase<IPharmacist> {
  readonly propertyName = 'pharmacists';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'pharmacists');
  }

  public async createPharmacist(pharmacist: IPharmacist & { username: string; pharmacy_id: string }) {
    return axios
      .post<any>(
        `${environment.api_base_url}pharmacy/pharmacists`,
        pharmacist,
        await super.buildConfig(undefined, 'application/json'),
      )
      .then(result => result.data.password as string);
  }

  public async getUsername(id: string, config: AxiosRequestConfig) {
    return (await axios
      .get(
        `${environment.api_base_url}pharmacy/pharmacists/id/${id}`,
        config,
      )
    ).data;
  }

  public findAll(params?: { limit?: string }): Promise<IPharmacist[]> {
    return super.findAll(params);
  }

  public findAllWithPagination(params: { status?: string; limit?: number; last_key?: string } = { limit: 25 }) {
    return super.findAllWithPagination(params).then(result => ({
      [this.propertyName]: (result[this.propertyName] ?? []) as IPharmacist[],
      pagination: result.pagination as { totalrecords: number; displayrecords: number; last_key: string },
    }));
  }
}
