import { Component, OnInit } from '@angular/core';
import { ReservationSlotService } from 'src/services/api/reservation-slot.service';
import { IPharmacy, IReservationSlot } from '../../../models';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Options } from '@angular-slider/ngx-slider';
import { ITimeframe } from 'src/models/reservation-slot';
import { FormControl } from '@angular/forms';
import { getLoginSession } from 'src/app/modules/storeModules';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';

type dayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;
const defaultInitial = [12, 12];

@Component({
  selector: 'app-reservation-slots',
  templateUrl: './reservation-slots.component.html',
  styleUrls: ['./reservation-slots.component.scss'],
})
export class ReservationSlotsComponent implements OnInit {
  reservationSlots: IReservationSlot[] = [];

  pharmacy: IPharmacy | null = null;
  pharmacyId = '';

  totalRecords = 0;
  pageNumber = 0;
  readonly pageSize = 100;
  private lastKeys: string[] = [];

  // --------------------------------------------------------------------------
  // デフォルト設定関連
  defaultId: string | null = null;
  defaultSaving = false;
  days = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
  defaultFormControls: FormControl[][] = [
    [new FormControl(defaultInitial)],
    [new FormControl(defaultInitial)],
    [new FormControl(defaultInitial)],
    [new FormControl(defaultInitial)],
    [new FormControl(defaultInitial)],
    [new FormControl(defaultInitial)],
    [new FormControl(defaultInitial)],
  ];

  options: Options = {
    floor: 0,
    ceil: 24,
    step: 0.5,
    showTicks: true,
  };

  holidayCheck: boolean = false;
  // --------------------------------------------------------------------------

  loading = true;

  constructor(private router: Router, private reservationSlotService: ReservationSlotService, private store: Store) { }
  async ngOnInit(): Promise<void> {
    this.pharmacy = await getLoginSession(this.store)
      .pipe(
        first(),
        map(s => s.pharmacy),
      )
      .toPromise();
    this.pharmacyId = this.pharmacy?.id ?? '';
    this.loading = true;
    try {
      await Promise.all([this.fetchReservationSlots(), this.fetchDefaultReservationSlots()]);
    } finally {
      this.loading = false;
    }
  }

  private async fetchDefaultReservationSlots() {
    const result = await this.reservationSlotService.fetchDefaults();
    this.defaultId = result.length === 0 ? null : result[0].id;
    if (this.defaultId === null) {
      return;
    }
    const controls = this.defaultFormControls;
    const pick = (day: number) => result[0].timeframes.filter(tf => tf.day === `${day}`);
    const set = (day: dayNumber) => (timeframe: ITimeframe) => (index: number) => {
      if (controls[day][index]) {
        controls[day][index].setValue(this.timeframeToTuple(timeframe));
      } else {
        controls[day].push(new FormControl(this.timeframeToTuple(timeframe)));
      }
    };

    for (let i: dayNumber = 0; i <= 6; i++) {
      console.log('pick', i, pick(i));
      pick(i).forEach((tf, j) => set(i as dayNumber)(tf)(j));
    }

    this.holidayCheck = result[0].holiday_check ?? false;
  }

  private async fetchReservationSlots(params?: { last_key?: string }): Promise<void> {
    const pagination = await this.reservationSlotService
      .findAllWithPagination({ ...params, limit: this.pageSize })
      .then(data => {
        this.reservationSlots = data.reservations;
        return data.pagination;
      });
    this.totalRecords = pagination.totalrecords;
    if (pagination.last_key && this.pageNumber >= this.lastKeys.length) {
      this.lastKeys.push(pagination.last_key);
    }
  }

  async pageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    try {
      this.loading = true;
      await this.fetchReservationSlots({
        last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
      });
    } finally {
      this.loading = false;
    }
  }

  async reloadEvent() {
    this.loading = true;
    this.pageNumber = 0;
    this.lastKeys = [];
    try {
      await this.fetchReservationSlots();
    } finally {
      this.loading = false;
    }
  }

  toDetailPage(slotId: string) {
    this.router.navigate(['pharmacy/reservation-slots/detail'], { queryParams: { slotId } });
  }

  addDefaultReservationSlot(day: number) {
    this.defaultFormControls[day].push(new FormControl(defaultInitial));
  }

  deleteDefaultReservationSlot(day: number, i: number) {
    this.defaultFormControls[day].splice(i);
  }

  async saveDefault() {
    this.defaultSaving = true;
    try {
      const timeframes = this.defaultFormControls.flatMap((controls, i) => {
        const createTimeFrame = this.createTimeFrame(i);
        return controls.map(c => createTimeFrame(c.value)).filter(t => t !== null);
      }) as ITimeframe[];
      const result = await this.reservationSlotService.saveDefault(this.defaultId, this.pharmacyId, timeframes, this.holidayCheck);
      if (this.defaultId === null) {
        // 初回設定時，保存を何度も押すとデフォルトの予約枠が複数できてしまうことを避ける。
        this.defaultId = result.data.id;
      }
      alert('デフォルト予約可能枠を設定しました。');
    } finally {
      this.defaultSaving = false;
    }
  }

  private createTimeFrame = (day: number) => (hourTuple: [number, number]): ITimeframe | null => {
    if (hourTuple[0] === hourTuple[1]) {
      return null;
    }
    const [start, end] = hourTuple.map(hour => {
      const hourText = `${Math.floor(hour)}`.padStart(2, '0');
      const minute = parseInt(`${hour}`.split('.')[1] ?? 0, 10) * 6;
      const minuteText = `${minute}`.padStart(2, '0');
      return `${hourText}:${minuteText}`;
    });
    return { type: 'open', year: '*', month: '*', date: '*', day: `${day}`, timezone: '+9', start, end };
  };

  private timeframeToTuple(timeframe: ITimeframe): [number, number] {
    return [timeframe.start, timeframe.end]
      .map<[number, number]>(timeText => timeText.split(':').map(s => parseInt(s, 10)) as [number, number])
      .map(tuple => tuple[0] + tuple[1] / 60) as [number, number];
  }
}
