import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ICBFTemplate } from 'src/models/cbf-template';
import { Store } from '@ngrx/store';
import { IQAT, QuestionType } from 'src/models/qa-template';
import { ActivatedRoute, Router } from '@angular/router';
import { CBFTemplateService } from 'src/services/api/cbf-template.service';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { filter, map } from 'rxjs/operators';
import { getLoginSession } from 'src/app/modules/storeModules';

@Component({
  selector: 'app-cbf-template-detail',
  templateUrl: './cbf-template-detail.component.html',
  styleUrls: ['./cbf-template-detail.component.scss'],
})
export class CbfTemplateDetailComponent implements OnInit {
  templateId = '';
  pharmacyId = '';
  name = '新しいテンプレート';
  qaTemplate: ICBFTemplate | null = null;
  questions: IQAT[] = [];
  isBeingEdited: boolean[] = [];
  loading = true;
  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private pcfTemplateService: CBFTemplateService,
  ) {}

  async ngOnInit(): Promise<void> {
    getLoginSession(this.store)
      .pipe(
        map(s => s.pharmacy),
        filter(isNotNull),
      )
      .subscribe(async pharmacy => {
        this.pharmacyId = pharmacy.id;
      });

    this.templateId = this.route.snapshot.paramMap.get('templateId') ?? '';
    if (this.templateId === 'new-template') {
      this.questions = [{ type: QuestionType.radio, question: '', answers: [''], required: false }];
      this.loading = false;
    } else {
      this.qaTemplate = await this.fetchTemplate(this.templateId);
      if (this.qaTemplate === null) {
        this.router.navigate(['/pharmacy/pcf-templates']);
      }
      this.questions = this.qaTemplate?.qa ?? [];
      this.name = this.qaTemplate?.name ?? '';
    }
    this.isBeingEdited = Array(this.questions.length).fill(false);
    this.questions.filter(q => !q.answers).forEach(q => (q.answers = ['']));
    if (this.templateId === 'new-template') {
      this.isBeingEdited[0] = true;
    }
  }

  async fetchTemplate(templateId: string) {
    try {
      this.loading = true;
      return await this.pcfTemplateService.find(templateId);
    } finally {
      this.loading = false;
    }
  }

  drop(event: CdkDragDrop<IQAT[]>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
    moveItemInArray(this.isBeingEdited, event.previousIndex, event.currentIndex);
  }

  addQuestion() {
    this.questions.push({ type: QuestionType.radio, question: '', answers: [''], required: false });
    this.isBeingEdited.fill(false).push(true);
  }

  removeQuestion(index: number) {
    this.questions.splice(index, 1);
  }

  editQuestion(index: number) {
    if (this.isBeingEdited[index]) {
      this.isBeingEdited[index] = false;
    } else {
      this.isBeingEdited.fill(false);
      this.isBeingEdited[index] = true;
    }
  }

  addChoice(index: number) {
    this.questions[index].answers.push('');
  }

  removeChoice(question_index: number, choice_index: number) {
    this.questions[question_index].answers.splice(choice_index, 1);
  }

  choiceTrackBy(index: number, obj: any) {
    return index;
  }

  async save(): Promise<void> {
    if (this.templateId === 'new-template') {
      if (!confirm('この内容でテンプレートを新規作成しますか？')) {
        return;
      }
      this.loading = true;
      await this.pcfTemplateService.create({
        pharmacy_id: this.pharmacyId,
        name: this.name,
        qa: this.formatQuestions(this.questions),
      });
    } else {
      if (!confirm('変更内容を保存しますか？')) {
        return;
      }
      this.loading = true;
      await this.pcfTemplateService.update({
        id: this.templateId,
        pharmacy_id: this.pharmacyId,
        name: this.name,
        qa: this.formatQuestions(this.questions),
      });
    }
    this.router.navigate(['/pharmacy/cbf-templates']);
  }

  private formatQuestions(questions: IQAT[]): IQAT[] {
    return questions.map(q =>
      q.type === QuestionType.checkbox || q.type === QuestionType.radio
        ? { type: q.type, question: q.question, answers: q.answers, required: q.required }
        : { type: q.type, question: q.question, answers: [], required: q.required },
    );
  }
}
