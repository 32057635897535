import { Action, ActionReducer, ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';

import { environment } from '../../../environments/environment';
import * as fromSession from './session.reducer';

export interface State {
  [fromSession.sessionFeatureKey]: fromSession.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromSession.sessionFeatureKey]: fromSession.reducer,
};

export function logger(reducer: ActionReducer<State, Action>) {
  return (state: State, action: Action) => {
    const newState = reducer(state, action);
    console.log('action', action);
    console.log('state', newState);
    return newState;
  };
}

export const metaReducers: MetaReducer<State, Action>[] = !environment.production ? [logger as any] : [];

export const selectSession = (state: State) => state.session;
export const getLoading = createSelector(selectSession, fromSession.getSessionLoading);
export const getSession = createSelector(selectSession, fromSession.getSessionData);
