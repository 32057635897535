import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControlOptions, Form, FormControl, ValidatorFn, Validators } from '@angular/forms';

export type Validation = {
  validator: ValidatorFn;
  type: string;
  message: string;
};
@Component({
  selector: 'form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements OnInit {
  @Input() model: string = '';
  @Output() modelEvent = new EventEmitter<string>();
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() type: 'text' | 'password' = 'text';
  @Input() isRequired: boolean = false;
  @Input() validations: Validation[] = [];
  formControl: FormControl | null = null;

  get filteredValidations() {
    return this.validations.filter(v => this.formControl?.hasError(v.type));
  }

  constructor() {}

  ngOnInit(): void {
    if (this.validations.length !== 0) {
      this.formControl = new FormControl(
        this.model,
        this.validations.map(v => v.validator),
      );
    }
  }

  changeValue() {
    this.modelEvent.emit(this.model);
  }
}
