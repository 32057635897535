import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AsyncScheduler,
  AudioVideoObserver,
  ConsoleLogger,
  DataMessage,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSession,
  MeetingSessionConfiguration,
  RealtimeAttendeePositionInFrame,
  VideoTileState,
} from 'amazon-chime-sdk-js';
import { AxiosRequestConfig } from 'axios';
import { filter, map, take } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { ChatComponent, Post } from 'src/app/parts/chat/chat.component';
import { IPatientInfo, IPCI, IPharmacist } from 'src/models';
import { InstructionStatus, ReservationForList } from 'src/models/pci';
import { IQAT } from 'src/models/qa-template';
import { Session } from 'src/models/session';
import { CallService } from 'src/services/api/call.service';
import { PciService } from 'src/services/api/pci.service';
import { PharmacistService } from 'src/services/api/pharmacist.service';
import { CognitoService } from 'src/services/cognito.service';
import * as fromSession from '../../app-store/reducers';
import { MeetingEndDialogComponent } from './meeting-end-dialog/meeting-end-dialog.component';

@Component({
  selector: 'app-implementation',
  templateUrl: './implementation.component.html',
  styleUrls: ['./implementation.component.scss'],
})
export class ImplementationComponent implements OnInit, OnDestroy {
  static readonly LOGGER_BATCH_SIZE: number = 85;
  static readonly LOGGER_INTERVAL_MS: number = 2000;
  static readonly MAX_MEETING_HISTORY_MS: number = 5 * 60 * 1000;
  static readonly DATA_MESSAGE_TOPIC: string = 'chat';
  static readonly DATA_MESSAGE_LIFETIME_MS: number = 300000;

  @ViewChild(ChatComponent) child: any;
  isMeetingStarted = false;
  isCalling = false;

  session?: Session;
  pci?: ReservationForList;
  pharmacists: IPharmacist[] = [];
  patientInfo?: IPatientInfo;
  displayedColumns: string[] = ['timeText', 'patientName', 'pharmacistName', 'wasConfirmed'];
  selection: any = null;

  meetingSession?: DefaultMeetingSession;
  muted = false;
  useCamera = true;

  videoInputDevices: MediaDeviceInfo[] = [];
  audioInputDevices: MediaDeviceInfo[] = [];
  audioOutputDevices: MediaDeviceInfo[] = [];
  videoSelection = 0;
  inputSelection = 0;
  outputSelection = 0;

  lastMessageSender: string | null = null;
  lastReceivedMessageTimestamp = 0;

  posts: Post[] = [];

  qaAnswer: IQAT[] = [];

  updatePciAnswerTimerId = 0;
  getCallingStatusTimerId = 0;
  count = 0;
  pharmacistId = '';
  callId = '';
  isCancelled = false;
  loading = false;
  saveQa = false;

  private callingSound: HTMLAudioElement = new Audio('assets/calling.mp3');

  get isConfirmed() {
    return this.pci?.status === InstructionStatus.confirmed;
  }

  get isPerformed() {
    return this.pci?.status === InstructionStatus.meeting_performed;
  }

  get isMeetingOver(): boolean {
    return this.pci?.status === InstructionStatus.meeting_completed;
  }

  get isMeetingStartedInServer(): boolean {
    return this.pci?.is_meeting_started ?? false;
  }

  get showVideoAudioSelectionArea(): boolean {
    return !(
      this.videoInputDevices.length === 0 ||
      this.audioInputDevices.length === 0 ||
      this.audioOutputDevices.length === 0
    );
  }

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private pciService: PciService,
    private callService: CallService,
    private cognito: CognitoService,
    public dialog: MatDialog,
    private pharmacistsService: PharmacistService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.callingSound.load();
    this.store.select<Session>(fromSession.getSession as any).subscribe(s => (this.session = s));
    getLoginSession(this.store)
      .pipe(
        map(s => s.pharmacist),
        filter(isNotNull),
        take(1),
      )
      .subscribe(pharmacist => {
        this.pharmacistId = pharmacist?.id;
      });
    this.pci = await this.fetchPci();
    this.isMeetingStarted = this.isMeetingStartedInServer;
    this.qaAnswer = this.pci.pci_answers ?? [];
  }

  ngOnDestroy() {
    this.clearInterval();
    this.meetingSession?.audioVideo.stop();
  }

  async initializeMeetingSession(configuration: MeetingSessionConfiguration): Promise<void> {
    const logger = new ConsoleLogger('ChimeMeetingLogs', LogLevel.OFF);
    const deviceController = new DefaultDeviceController(logger);
    this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
    const meetingSession = this.meetingSession;

    this.videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();
    this.audioInputDevices = await meetingSession.audioVideo.listAudioInputDevices();
    this.audioOutputDevices = await meetingSession.audioVideo.listAudioOutputDevices();

    console.log(this.videoInputDevices);
    meetingSession.audioVideo.chooseVideoInputQuality(960, 540, 15, 1000);
    await Promise.all([this.selectVideoInputDevice(), this.selectAudioInputDevice(), this.selectAudioOutputDevice()]);

    const pharmacistVideoElement = getVideoElement('video-pharmacist');
    const patientVideoElement = getVideoElement('video-patient');

    const audioElement = document.getElementById('meeting-audio');
    if (audioElement === null) {
      throw new Error('audioElementが見つかりませんでした');
    }
    meetingSession.audioVideo.bindAudioElement(audioElement as HTMLAudioElement);
    if (this.muted) {
      this.meetingSession?.audioVideo.realtimeMuteLocalAudio();
    } else {
      this.meetingSession?.audioVideo.realtimeUnmuteLocalAudio();
    }

    meetingSession.audioVideo.addObserver(
      this.videoManagementObserver(meetingSession, pharmacistVideoElement, patientVideoElement),
    );

    const callback = async (
      attendeeId: string,
      present: boolean,
      externalUserId?: string,
      dropped?: boolean,
      posInFrame?: RealtimeAttendeePositionInFrame | null,
    ): Promise<void> => {
      console.log(
        `Attendee ID: ${attendeeId} Present: ${present}, posInFrame.attendeeIndex: ${posInFrame?.attendeeIndex}, posInFrame.attendeesInFrame: ${posInFrame?.attendeesInFrame}`,
      );
      if (present && posInFrame?.attendeesInFrame && posInFrame.attendeeIndex !== null) {
        this.count++;
        if (2 <= this.count) {
          this.isCalling = false;
          this.updatePciAnswerTimerId = window.setInterval(() => {
            if (!this.pci) {
              throw new Error('pciが取得できませんでした。');
            }
            this.pciService.updatePciAnswer(this.pci, this.qaAnswer);
          }, 0.5 * 60 * 1000);
        }
      } else {
        console.log('患者が退出したため服薬指導を終了します');
        alert('患者が退出したため服薬指導を終了します');
        if (!this.pci) {
          throw new Error('pciが取得できませんでした。');
        }
        await this.meetingSession?.audioVideo.stop();
        await this.pciService.endMeeting(this.pci.id, await this.config()).then(response => {
          if (response?.status === 200) {
            this.endMeeting('patient');
            return;
          }
          this.clearInterval();
        });
      }
    };

    meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(callback);

    meetingSession.audioVideo.start();
    meetingSession.audioVideo.startLocalVideoTile();

    this.setupDataMessage();
  }

  async startMeeting() {
    if (!this.pci) {
      throw new Error('pciが取得できなかったため、服薬指導を開始できません。');
    }
    this.isCalling = true;
    this.loading = true;
    this.callingSound.loop = true;
    this.callingSound.play();
    await this.joinMeeting(this.pci.original, this.pci.pharmacistName);
  }

  toggleMute() {
    this.muted = !this.muted;
    if (this.muted) {
      this.meetingSession?.audioVideo.realtimeMuteLocalAudio();
    } else {
      this.meetingSession?.audioVideo.realtimeUnmuteLocalAudio();
    }
  }

  async toggleCamera() {
    if (!this.meetingSession) {
      throw new Error('meeting session was not started.');
    }
    this.useCamera = !this.useCamera;
    if (this.useCamera) {
      try {
        const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();
        await this.meetingSession.audioVideo.chooseVideoInputDevice(videoInputDevices[this.videoSelection].deviceId);
        this.meetingSession.audioVideo.startLocalVideoTile();
      } catch (err) {
        console.log('no video input device selected');
      }
    } else {
      this.meetingSession?.audioVideo.stopLocalVideoTile();
    }
  }

  sendMessage(messageText: string): void {
    new AsyncScheduler().start(() => {
      const textToSend = messageText;
      if (!textToSend) {
        return;
      }
      this.meetingSession?.audioVideo.realtimeSendDataMessage(
        ImplementationComponent.DATA_MESSAGE_TOPIC,
        textToSend,
        ImplementationComponent.DATA_MESSAGE_LIFETIME_MS,
      );

      // echo the message to the handler
      if (!this.meetingSession?.configuration?.credentials?.attendeeId) {
        throw new Error('attendeeIdが存在しません。');
      }
      if (!this.meetingSession?.configuration?.credentials?.externalUserId) {
        throw new Error('externalUserIdが存在しません。');
      }
      this.dataMessageHandler(
        new DataMessage(
          Date.now(),
          ImplementationComponent.DATA_MESSAGE_TOPIC,
          new TextEncoder().encode(textToSend),
          this.meetingSession?.configuration?.credentials?.attendeeId,
          this.meetingSession?.configuration?.credentials?.externalUserId,
        ),
      );
    });
  }

  dataMessageHandler(dataMessage: DataMessage): void {
    if (!dataMessage.throttled) {
      if (!this.meetingSession?.configuration.credentials?.attendeeId) {
        throw new Error('attendeeIdが存在しません。');
      }
      const isSelf = dataMessage.senderAttendeeId === this.meetingSession?.configuration.credentials?.attendeeId;
      if (dataMessage.timestampMs <= this.lastReceivedMessageTimestamp) {
        return;
      }
      this.lastReceivedMessageTimestamp = dataMessage.timestampMs;
      this.posts.push({
        message: dataMessage.text(),
        isSelf,
      });
      if (!isSelf) {
        this.child.scroll();
      }
    } else {
      console.log('Message is throttled. Please resend');
    }
  }

  setupDataMessage(): void {
    this.meetingSession?.audioVideo.realtimeSubscribeToReceiveDataMessage(
      ImplementationComponent.DATA_MESSAGE_TOPIC,
      (dataMessage: DataMessage) => {
        this.dataMessageHandler(dataMessage);
      },
    );
  }

  async endMeeting(type: 'pharmacist' | 'patient') {
    if (this.pci === undefined) {
      return;
    }
    this.pciService.updatePciAnswer(this.pci.original, this.qaAnswer);

    const dialogRef = this.dialog.open(MeetingEndDialogComponent, {
      data: { pci: this.pci, meetingSession: this.meetingSession, type },
      minWidth: 400,
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log(`Dialog result: ${result}`);
      if (result === undefined) {
        return;
      }
      this.clearInterval();
      this.isMeetingStarted = false;
      this.isCalling = false;
      this.loading = false;
      this.isCancelled = false;
      this.count = 0;
      this.callingSound.pause();
      alert('服薬指導を終了しました。');

      this.pci = await this.fetchPci();
      if (this.isMeetingOver) {
        this.router.navigate([`/pharmacist/reservation/${this.pci?.id}`]);
      }
    });
  }

  async endMeetingFinaly(type: 'pharmacist' | 'patient') {
    if (this.pci === undefined) {
      return;
    }
    this.pciService.updatePciAnswer(this.pci.original, this.qaAnswer);

    this.clearInterval();
    this.isMeetingStarted = false;
    this.isCalling = false;
    this.loading = false;
    this.isCancelled = false;
    this.count = 0;
    this.callingSound.pause();
    alert('服薬指導を終了しました。');
    const config = await this.config();
    await this.pciService.completeMeeting(this.pci.id, config);

    this.pci = await this.fetchPci();
    if (this.isMeetingOver) {
      this.router.navigate([`/pharmacist/reservation/${this.pci?.id}`]);
    }

  }

  async incompleteMeeting() {
    const message = `服薬指導を中止します。再度実施できませんが、よろしいですか？`;
    if (confirm(message)) {
      if (!this.pci) {
        throw new Error('pciが取得できませんでした。');
      }
      const config = await this.config();
      await this.pciService.incompleteMeeting(this.pci.id, config);
      this.isMeetingStarted = false;
      this.router.navigate([`/pharmacist/reservation/${this.pci?.id}`]);
    }
  }

  async stopCall() {
    const message = `呼び出しを終了します。よろしいですか？`;
    if (confirm(message)) {
      try {
        if (!this.pci) {
          throw new Error('pciが取得できませんでした。');
        }
        this.clearInterval();
        const config = await this.config();
        await this.meetingSession?.audioVideo.stop();
        await this.callService.cancel(this.callId, config);
        await this.pciService.endMeeting(this.pci.id, config);
        this.isMeetingStarted = false;
        this.isCalling = false;
        this.loading = false;
        this.count = 0;
        this.callingSound.pause();
        this.pci = await this.fetchPci();
      } catch (error) {
        alert(`呼び出しを終了できませんでした。`);
      }
    }
  }

  private clearInterval() {
    window.clearInterval(this.updatePciAnswerTimerId);
    window.clearInterval(this.getCallingStatusTimerId);
  }

  async savePciQa() {
    if (!this.pci) {
      throw new Error('pciが取得できませんでした。');
    }
    const message = `服薬指導QAを保存します。よろしいですか？`;
    if (confirm(message)) {
      this.saveQa = true;
      await this.pciService.updatePciAnswer(this.pci, this.qaAnswer);
      this.saveQa = false;
    }
  }

  async complete() {
    if (!this.pci) {
      throw new Error('pciが取得できませんでした。');
    }
    const message = `服薬指導を完了にすると、服薬指導QAへの入力等ができなくなります。よろしいですか？`;
    if (confirm(message)) {
      const config: AxiosRequestConfig = {};
      config.headers = {};
      const token = await this.cognito.getAccessToken();
      config.headers.Authorization = token.getJwtToken();
      config.headers['Content-Type'] = 'application/json';
      await this.pciService.complete(this.pci.id, config);
      this.router.navigate([`/pharmacist/reservation/${this.pci?.id}`]);
    }
  }

  selectVideoInputDevice() {
    const device = this.videoInputDevices[this.videoSelection];
    if (device === undefined) {
      throw new Error('video device not found');
    }
    return this.meetingSession?.audioVideo.chooseVideoInputDevice(device);
  }

  selectAudioInputDevice() {
    const device = this.audioInputDevices[this.inputSelection];
    if (device === undefined) {
      throw new Error('audio input device not found');
    }
    return this.meetingSession?.audioVideo.chooseAudioInputDevice(device.deviceId);
  }

  selectAudioOutputDevice() {
    const device = this.audioOutputDevices[this.outputSelection];
    if (device === undefined) {
      throw new Error('audio output device not found');
    }
    return this.meetingSession?.audioVideo.chooseAudioOutputDevice(device.deviceId);
  }

  private async fetchPci() {
    const pci = await this.pciService.find(this.route.snapshot.paramMap.get('pciId') ?? '');
    this.pharmacists = await this.pharmacistsService.findAll();

    if (pci === null) {
      throw new Error('fetch pci failed.');
    }
    this.patientInfo = pci.patient_info;
    return new ReservationForList(pci, this.pharmacists);
  }

  private async joinMeeting(pci: IPCI, pharmacistsName: string) {
    console.log('join meeting start');

    const config = await this.config();

    this.callId = (await this.pciService.startMeeting(pci.id, config)).data.id;

    this.getCallingStatusTimerId = window.setInterval(async () => {
      try {
        const response = await this.callService.getStatus(this.callId);
        if (!response || !response.callees) {
          throw new Error('ステータスが取得できなかったため、服薬指導を続行できませんでした。');
        }
        if (response.callees.some(b => b.status === 'joined')) {
          this.isCalling = false;
          this.loading = false;
          this.isCancelled = false;
          this.callingSound.pause();
          window.clearInterval(this.getCallingStatusTimerId);
          return;
        }
        if (response.callees.some(b => b.status === 'received' || b.status === 'sent')) {
          return;
        }
        this.clearInterval();
        alert(`応答がありませんでした。服薬指導を終了します。`);
        if (!this.pci) {
          throw new Error('pciが取得できませんでした。');
        }
        await this.meetingSession?.audioVideo.stop();
        await this.pciService.endMeeting(this.pci.id, config);
        this.endMeeting('patient');
      } catch (error) {
        await this.meetingSession?.audioVideo.stop();
        if (!this.pci) {
          throw new Error('pciが取得できませんでした。');
        }
        await this.pciService.endMeeting(this.pci.id, config);
        this.clearInterval();
        throw new Error('ステータスが取得できなかったため、服薬指導を続行できませんでした。');
      }
    }, 0.1 * 60 * 1000);

    if (!pci.pharmacy_id || !pci.patient_info_id || !pci.patient_account_id) {
      throw new Error('薬局ID・患者ID・患者情報IDのいずれかが取得できませんでした。');
    }

    const info = (
      await this.callService.start({
        id: this.callId,
        pharmacy_id: pci.pharmacy_id,
        pharmacist_id: this.pharmacistId,
        patient_info_id: pci.patient_info_id,
        patient_id: pci.patient_account_id,
      })
    ).data;
    this.isCancelled = true;
    this.isMeetingStarted = true;
    console.log(info);
    const configuration = new MeetingSessionConfiguration(info.join_info.Meeting, info.join_info.Attendee);
    await this.initializeMeetingSession(configuration);
  }

  private videoManagementObserver(
    meetingSession: MeetingSession,
    pharmacistVideoElement: HTMLVideoElement,
    patientVideoElement: HTMLVideoElement,
  ): AudioVideoObserver {
    return {
      audioVideoDidStart: () => {
        console.log('Started');
      },
      videoTileDidUpdate: (tileState: VideoTileState) => {
        console.log('tile updated!', tileState);

        if (!tileState.boundAttendeeId || tileState.isContent || tileState.tileId === null) {
          return;
        }

        console.log('Start video', tileState.boundExternalUserId);
        if (tileState.localTile) {
          meetingSession.audioVideo.bindVideoElement(tileState.tileId, pharmacistVideoElement);
        } else {
          meetingSession.audioVideo.bindVideoElement(tileState.tileId, patientVideoElement);
        }
      },
    };
  }

  private async config(): Promise<AxiosRequestConfig> {
    return {
      headers: {
        Authorization: (await this.cognito.getAccessToken()).getJwtToken(),
        'Content-Type': 'application/json',
      },
    };
  }
}

const getVideoElement = (id: string) => {
  const v = document.getElementById(id);
  if (v === null) {
    throw new Error('videoElementが見つかりませんでした');
  }
  return v as HTMLVideoElement;
};
