import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { PcfStatus } from 'src/models/pcf';

@Pipe({ name: 'pcf_status' })
export class PcfStatusPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === PcfStatus.confirmed,
        _ => '確認済み',
      )
      .on(
        v => v === PcfStatus.answered,
        _ => '回答済み',
      )
      .on(
        v => v === PcfStatus.read,
        _ => '送信済み（既読）',
      )
      .on(
        v => v === PcfStatus.sent,
        _ => '送信済み（未読）',
      )
      .on(
        v => v === PcfStatus.send,
        _ => '送信予約済み',
      )
      .on(
        v => v === PcfStatus.unknown,
        _ => '不明',
      )
      .otherwise(v => '不明');
  }
}
