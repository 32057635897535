import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import axios, { AxiosRequestConfig } from 'axios';
import { environment } from 'src/environments/environment';
import { ICertificate } from 'src/models/health-ins-card';
import { CognitoService } from '../cognito.service';
import { ServiceBase } from '../service-base';
import { IPatientInfo, IPharmacyPatientInfo } from 'src/models/patient-info';
import { IHealthInsuranceCard } from 'src/models';

@Injectable({
  providedIn: 'root',
})
export class PatientInfoService extends ServiceBase<any> {
  readonly propertyName = 'patient_infos';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'patient_infos');
  }

  public find(id: string): Promise<IPatientInfo> {
    return super.find(id);
  }

  public findAll(offset?: number, limit?: number, patient_account_id?: string): Promise<IPharmacyPatientInfo[]> {
    const params = {
      offset,
      limit,
      patient_account_id,
    };
    return super.findAll(params);
  }

  public findAllWithPagination(params?: { limit?: number; last_key?: string }) {
    return super.findAllWithPagination(params).then(result => ({
      [this.propertyName]: (result[this.propertyName] ?? []) as IPharmacyPatientInfo[],
      pagination: result.pagination,
    }));
  }

  public async findPharmacyPatientInfo(
    patientInfoId: string,
    config: AxiosRequestConfig,
  ): Promise<IPharmacyPatientInfo> {
    return (
      await axios.get(
        environment.api_base_url + `pharmacist/patient_info/${patientInfoId}`,
        config,
      )
    ).data;
  }

  public async getPublicBeneficiaryCertificates(
    patientInfoId: string,
    index: number,
    config: AxiosRequestConfig,
  ): Promise<ICertificate> {
    return (
      await axios.get(
        environment.api_base_url + `pharmacist/patient_infos/${patientInfoId}/certificates/${index}/image`,
        config,
      )
    ).data;
  }

  public async getInsuranceCard(patientInfoId: string, config: AxiosRequestConfig): Promise<IHealthInsuranceCard> {
    return (
      await axios.get(
        environment.api_base_url + `pharmacist/patient_infos/${patientInfoId}/insurance_card/image`,
        config,
      )
    ).data;
  }
}
