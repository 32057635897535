import { Injectable } from '@angular/core';
import { IPCFTemplate } from 'src/models';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PCFTemplateService extends ServiceBase<IPCFTemplate> {
  readonly propertyName = 'pcf_templates';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'pcf_templates');
  }

  public findAll(params?: { limit?: number }): Promise<IPCFTemplate[]> {
    return super.findAll(params);
  }

  public findAllWithPagination(params: { status?: string; limit?: number; last_key?: string } = { limit: 25 }) {
    return super.findAllWithPagination(params).then(result => ({
      [this.propertyName]: (result[this.propertyName] ?? []) as IPCFTemplate[],
      pagination: result.pagination as { totalrecords: number; displayrecords: number; last_key: string },
    }));
  }
}
