<h2 mat-dialog-title>{{ this.patientName }}様予約確認</h2>
<mat-dialog-content class="mat-typography">
  <mat-radio-group fxLayout="column" [(ngModel)]="selectedTimeFrame" class="radio-group">
    <mat-radio-button class="radio-button" *ngFor="let timeFrame of timeFrames; index as i" [value]="i">{{
      timeFrame.start_time | date: 'yyyy/MM/dd HH:mm'
    }}</mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions>
  <loading-button
    [name]="'予約確定'"
    color="primary"
    [loading]="confirmButtonLoading"
    (click)="confirm()"
    icon="check"
  ></loading-button>
  <loading-button
    [name]="'お断り'"
    color="warn"
    [loading]="declineButtonLoading"
    fxFlexOffset="auto"
    (click)="decline()"
    icon="cancel"
  ></loading-button>
  <button mat-button mat-dialog-close fxFlexOffset="auto">閉じる</button>
</mat-dialog-actions>
