<h2 class="dialog-title" mat-dialog-title>薬剤師{{ pharmacist === null ? '登録' : '詳細' }}</h2>
<mat-dialog-content class="mat-typography dialog-content">
  <div fxLayout="column" fxLayoutGap="15px" *ngIf="newPassword === ''">
    <mat-form-field *ngIf="!this.pharmacist">
      <mat-label>ログインID</mat-label>
      <input matInput type="text" [formControl]="loginIdFormControl" required />
      <mat-error *ngIf="loginIdFormControl.hasError('required')">ログインIDは<strong>必須</strong>です</mat-error>
      <mat-error *ngIf="!loginIdFormControl.hasError('required')&&loginIdFormControl.hasError('pattern')">
        ログインIDは<strong>英数字</strong>(A-Z,a-z,0-9)で入力してください
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="this.pharmacist">
      <mat-label>ログインID</mat-label>
      <input matInput type="text" value="{{username}}" readonly />
    </mat-form-field>

    <mat-form-field>
      <mat-label>姓</mat-label>
      <input matInput type="text" [formControl]="familyNameFormControl" required />
      <mat-error *ngIf="familyNameFormControl.hasError('required')">姓は<strong>必須</strong>です</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>名</mat-label>
      <input matInput type="text" [formControl]="givenNameFormControl" required />
      <mat-error *ngIf="givenNameFormControl.hasError('required')">名は<strong>必須</strong>です</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>姓かな</mat-label>
      <input matInput type="text" [formControl]="familyNameKanaFormControl" required />
      <mat-error *ngIf="familyNameKanaFormControl.hasError('required')">姓かなは<strong>必須</strong>です</mat-error>
      <mat-error *ngIf="!familyNameKanaFormControl.hasError('required')&&familyNameKanaFormControl.hasError('pattern')">
        <strong>ひらがな</strong>で入力してください
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>名かな</mat-label>
      <input matInput type="text" [formControl]="givenNameKanaFormControl" required />
      <mat-error *ngIf="givenNameKanaFormControl.hasError('required')">名かなは<strong>必須</strong>です</mat-error>
      <mat-error *ngIf="!givenNameKanaFormControl.hasError('required')&&givenNameKanaFormControl.hasError('pattern')">
        <strong>ひらがな</strong>で入力してください
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>メールアドレス</mat-label>
      <input matInput type="text" [formControl]="emailFormControl" />
      <mat-error *ngIf="emailFormControl.hasError('email')">メールアドレスの形式が正しくありません</mat-error>
    </mat-form-field>
  </div>
  <div class="new-password-area" *ngIf="newPassword !== ''">
    新しい薬剤師の仮パスワードは以下の通りです
    <div class="new-password">{{ newPassword }}</div>
    <div class="notice">このダイアログを閉じるとパスワードは二度と表示されません！</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
  <button fxFlex mat-button color="primary" (click)="close()" *ngIf="newPassword !== ''">
    閉じる
  </button>
  <button
    fxFlex
    mat-button
    mat-dialog-close
    [disabled]="saveButtonLoading||deleteButtonLoading"
    *ngIf="newPassword === ''"
    color="primary"
  >
    <mat-icon>cancel</mat-icon>
    閉じる
  </button>
  <div fxFlex fxLayout="row" fxLayoutAlign="space-around" *ngIf="newPassword === ''">
    <loading-button
      [name]="buttonName"
      color="primary"
      (click)="save()"
      [loading]="saveButtonLoading"
      [disabled]="!areAllFormsValid"
      icon="save"
    ></loading-button>
    <loading-button
      [name]="'無効化'"
      color="warn"
      *ngIf="!pharmacist?.deleted_at"
      (click)="disablePharmacist()"
      [loading]="deleteButtonLoading"
      icon="person_outline"
    ></loading-button>
    <loading-button
      [name]="'有効化'"
      color="warn"
      *ngIf="pharmacist?.deleted_at"
      (click)="enablePharmacist()"
      [loading]="deleteButtonLoading"
      icon="how_to_reg"
    ></loading-button>
    <loading-button
      [name]="'パスワード初期化'"
      color="warn"
      *ngIf="!pharmacist?.deleted_at"
      (click)="resetPharmacist()"
      [loading]="deleteButtonLoading"
      icon="password"
    ></loading-button>
  </div>
</mat-dialog-actions>
