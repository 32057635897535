import { Component, OnInit } from '@angular/core';
import { MatNoDataRow } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { IPatientInfo } from 'src/models';
import { IAlarmRegular } from 'src/models/alarm-regular';
import { IAlarmReluctance } from 'src/models/alarm-reluctance';
import { MedicationMethodFrequencyPipe } from 'src/pipes/medication_method_frequency.pipe';
import { AlarmRegularService } from 'src/services/api/alarm-regular.service';
import { AlarmReluctanceService } from 'src/services/api/alarm-reluctance.service';
import { PatientInfoService } from 'src/services/api/patient-info.service';

@Component({
  selector: 'app-alarm-data',
  templateUrl: './alarm-data.component.html',
  styleUrls: ['./alarm-data.component.scss'],
})
export class AlarmDataComponent implements OnInit {
  patientInfo: IPatientInfo | null = null;
  regularHistory: IAlarmRegular[] = [];
  reluctanceHistory: IAlarmReluctance[] = [];
  loading = true;
  isDeleted = false;
  year = 0;
  month = 0;

  constructor(
    private route: ActivatedRoute,
    private patientInfoService: PatientInfoService,
    private alarmRegularService: AlarmRegularService,
    private alarmReluctanceService: AlarmReluctanceService,
    private medicationMethodFrequencyPipe: MedicationMethodFrequencyPipe
  ) {
    this.loading = false;
  }

  async ngOnInit() {
    this.loading = true;
    this.patientInfo = await this.patientInfoService.find(this.route.snapshot.paramMap.get('patientInfoId') ?? '');
    if (!this.patientInfo) {
      this.isDeleted = true;
      this.loading = false;
      throw new Error('患者情報が存在しません。');
    }
    const now = new Date();
    this.year = now.getFullYear();
    this.month = now.getMonth() + 1;
    this.reloadData();
  }

  async reloadData() {
    this.loading = true;
    this.regularHistory = await this.alarmRegularService.findAll({ patient_info_id: this.patientInfo?.id, "year": this.year, "month": this.month });
    for (const element of this.regularHistory) {
      element.average_time = this.averageTime(element);
      element.average_rate_all = this.averageRate(element);
      element.average_rate_wday = this.averageRateWday(element);
      try {
        element.image = 'data:image/jpeg;base64,' + await this.alarmRegularService.getImage(element.medicine_id);
      } catch (e) {
        element.image = "";
      }
    }
    this.reluctanceHistory = await this.alarmReluctanceService.findAll({ patient_info_id: this.patientInfo?.id, "year": this.year, "month": this.month });
    for (const element of this.reluctanceHistory) {
      let dcount: number[] = new Array(31).fill(0);
      let all_count = 0;
      for (const untime of element.medication_result) {
        const mdate = new Date(untime);
        dcount[mdate.getDate()] = 1;
        all_count++;
      }
      element.all_count = all_count;
      element.day_count = dcount.reduce((sum, item) => { return sum + item; }, 0);
      try {
        element.image = 'data:image/jpeg;base64,' + await this.alarmReluctanceService.getImage(element.medicine_id);
      } catch (e) {
        element.image = "";
      }
    }
    this.loading = false;
  }

  prevMonth() {
    this.month--;
    if (this.month < 1) {
      this.year--;
      this.month += 12;
    }
    this.reloadData();
  }

  nextMonth() {
    this.month++;
    if (this.month > 12) {
      this.year++;
      this.month -= 12;
    }
    this.reloadData();
  }

  patternText(element: IAlarmRegular): string {
    let text: string = this.medicationMethodFrequencyPipe.transform(element.medication_method.frequency);
    if (element.medication_method.frequency === 'everyweek' && element.medication_method.frequency_wday) {
      text += '/' + element.medication_method.frequency_wday.map((wday: number) => {
        switch (wday) {
          case 0:
            return '日';
          case 1:
            return '月';
          case 2:
            return '火';
          case 3:
            return '水';
          case 4:
            return '木';
          case 5:
            return '金';
          case 6:
            return '土';
          default:
            return '';
        }
      }).join('');
    }
    if (element.medication_method.frequency === 'every other day') {
      if (element.medication_method.frequency_type === 1) {
        text += "/奇数日"
      }
      if (element.medication_method.frequency_type === 2) {
        text += "/偶数日"
      }
    }
    return text;
  }

  private averageTime(element: IAlarmRegular): string {
    let days: number = 0;
    let sum_ms: number = 0;
    element.medication_result.forEach((item) => {
      if (item.status === 'ok') {
        const jst = item.alarm_time + 9 * 60 * 60 * 1000;
        const day_begin = jst - (jst % (24 * 60 * 60 * 1000)) - 9 * 60 * 60 * 1000;
        sum_ms += (item.medication_time - day_begin);
        days++;
      }
    });
    if (days === 0) return '--:--';
    const hour = Math.floor((sum_ms / days) / (60 * 60 * 1000));
    const min = Math.floor((sum_ms / days) / (60 * 1000)) - hour * 60;
    return ('00' + hour).slice(-2) + ":" + ('00' + min).slice(-2);
  }

  private averageRate(element: IAlarmRegular): number {
    let ok: number = 0;
    let ng: number = 0;
    element.medication_result.forEach((item) => {
      if (item.status === 'ok') {
        ok++;
      }
      if (item.status === 'ng') {
        ng++;
      }
    });
    if (ok + ng === 0) return -1;
    return 100 * ok / (ok + ng);
  }

  private averageRateWday(element: IAlarmRegular): number[] {
    let ok: number[] = [...Array(7)].map(e => 0);
    let ng: number[] = [...Array(7)].map(e => 0);
    let rate: number[] = [];
    element.medication_result.forEach((item) => {
      const day = new Date(item.alarm_time);
      const wday = day.getDay();
      if (item.status === 'ok') {
        ok[wday]++;
      }
      if (item.status === 'ng') {
        ng[wday]++;
      }
    });
    for (let i = 0; i < 7; i++) {
      if (ok[i] + ng[i] === 0) {
        rate[i] = -1;
      } else {
        rate[i] = 100 * ok[i] / (ok[i] + ng[i]);
      }
    }
    return rate;
  }
}
