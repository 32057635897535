import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';

@Pipe({ name: 'payment_progress' })
export class PaymentProgressPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === 'created',
        _ => 20,
      )
      .on(
        v => v === 'started',
        _ => 50,
      )
      .on(
        v => v === 'completed',
        _ => 100,
      )
      .on(
        v => v === 'failed',
        _ => 100,
      )
      .on(
        v => v === 'cancelled',
        _ => 100,
      )
      .otherwise(_ => 0);
  }
}
