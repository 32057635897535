import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { when } from 'src/app/modules/when';
import { IPharmacist } from 'src/models';
import { InstructionStatus, Pci } from 'src/models/pci';
import { PcfService } from './api/pcf.service';
import { PciService } from './api/pci.service';
import { PrescriptionService } from './api/prescription.service';
import { NotificationService } from './notification.service';

export type NewArrivalType = 'pci' | 'pcf' | 'prescription';

export type NewArrival = {
  type: NewArrivalType;
  date?: number;
  patientName: string;
  title: string;
  routerLink?: string;
};

@Injectable({ providedIn: 'root' })
export class NewArrivalService {
  private _newArrivals = new BehaviorSubject<NewArrival[]>([]);
  private timeout?: NodeJS.Timeout;
  private lastFetchTime = 0;
  private doPolling = true;
  private pharmacist: IPharmacist | null = null;
  private readonly fetchInterval = 300000;

  constructor(
    private readonly store: Store,
    private readonly prescriptionService: PrescriptionService,
    private readonly pciService: PciService,
    private readonly pcfService: PcfService,
    private readonly notificationService: NotificationService,
    private readonly messaging: AngularFireMessaging,
  ) {
    const fetch = async () => {
      this._newArrivals.next(
        await this.fetchNewArrivals().catch(error => {
          console.log(error);
          return [];
        }),
      );
    };
    const resetTimer = async () => {
      if (this.timeout !== undefined) {
        clearInterval(this.timeout);
      }
      if (this.pharmacist) {
        this.lastFetchTime = new Date().getTime();
        console.log('[NewArrivalService] fetching', new Date(this.lastFetchTime).toLocaleTimeString());
        await fetch();
        if (this.doPolling) {
          this.timeout = setInterval(async () => {
            this.lastFetchTime = new Date().getTime();
            console.log('[NewArrivalService] fetching', new Date(this.lastFetchTime).toLocaleTimeString());
            await fetch();
          }, this.fetchInterval);
        }
      }
    };
    getLoginSession(this.store)
      .pipe(map(s => s.pharmacist))
      .subscribe(async pharmacist => {
        this.pharmacist = pharmacist;
        if (new Date().getTime() - this.lastFetchTime > 3000) {
          await resetTimer();
        }
      });
    this.notificationService.messageReceive.subscribe(async _ => {
      await resetTimer();
    });
    this.messaging.requestToken.pipe(filter(isNotNull), first()).subscribe(_ => {
      this.doPolling = false;
    });
  }

  get newArrivals(): Observable<NewArrival[]> {
    return this._newArrivals.asObservable();
  }

  private async fetchNewArrivals(): Promise<NewArrival[]> {
    const [pcis, prescriptions, pcfs] = await Promise.all([
      this.pciService.findAll({ status: 'requested,confirmed' }).then(result => result.map(r => new Pci(r))),
      this.prescriptionService.findAll(undefined, 'sent,confirmed,dispensed'),
      this.pcfService.findAll({ status: 'answered' }),
    ]);
    const filteredPcis = pcis
      .filter(r => r.status === InstructionStatus.requested || r.timeFrameOfTodayAndAfterCurrentTime)
      .map(p => {
        const date = p.updated_at;
        const patientName = `${p.patient_info?.family_name ?? '-'} ${p.patient_info?.given_name ?? '-'}`;
        const [title, routerLink] = when(p)
          .on(
            pci => pci.status === InstructionStatus.requested,
            () => ['確認が必要な服薬指導予約があります', '/pharmacist/reservation'],
          )
          .on(
            pci => !!pci.timeFrameOfTodayAndAfterCurrentTime,
            () => {
              // onの第一引数でundefinedでないことを確認済み
              // tslint:disable-next-line
              const startTime = new Date(p.timeFrameOfTodayAndAfterCurrentTime!.start_time);
              const startHour = startTime.getHours();
              const startMinutes = startTime.getMinutes();

              return [
                `本日${startHour}時${startMinutes}分から服薬指導の予約があります`,
                '/pharmacist/reservation/' + p.id,
              ];
            },
          )
          .otherwise(() => {
            throw new Error('invalid data.');
          });
        return { type: 'pci' as NewArrivalType, date, patientName, title, routerLink };
      });
    const filteredPrescriptions = prescriptions.map(p => {
      const date = p.updated_at;
      const patientName = `${p?.patient_info?.family_name ?? '-'} ${p?.patient_info?.given_name ?? '-'}`;
      const title = when(p.status)
        .on(
          s => s === 'sent',
          _ => '確認が必要な処方箋があります',
        )
        .on(
          s => s === 'confirmed',
          _ => '調剤待ちの処方箋があります',
        )
        .on(
          s => s === 'dispensed',
          _ => '完了待ちの処方箋があります',
        )
        .otherwise(_ => '');
      const routerLink = '/pharmacist/prescriptions/' + p.id;
      return { type: 'prescription' as NewArrivalType, date, patientName, title, routerLink };
    });
    const filteredPcfs = pcfs.map(f => ({
      type: 'pcf' as NewArrivalType,
      date: f.updated_at,
      patientName: `${f?.patient_info?.family_name ?? '-'} ${f?.patient_info?.given_name ?? '-'}`,
      title: '服薬フォローへの回答があります',
      routerLink: '/pharmacist/follow/',
    }));
    return [...filteredPcis, ...filteredPrescriptions, ...filteredPcfs].sort((a, b) => {
      if (a.date === undefined) {
        return 1;
      } else if (b.date === undefined) {
        return -1;
      } else {
        return b.date - a.date;
      }
    });
  }
}
