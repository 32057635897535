import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { format } from 'date-fns';
import { combineLatest } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import { when } from 'src/app/modules/when';
import { PopupCheckboxService } from 'src/app/parts/checkbox-list/checkbox-list.component';
import { IPharmacyPatientInfo } from 'src/models/patient-info';
import { PatientInfoService } from 'src/services/api/patient-info.service';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
})
export class PatientsListComponent implements OnInit {
  @ViewChild('favoriteHeader') private favoriteHeader!: CdkOverlayOrigin;
  readonly displayedColumns: readonly string[] = ['name', 'birth_date', 'sex', 'deactivated', 'activated_date', 'deactivated_date', 'alarm_exist'];
  loading = true;

  readonly pageSize = 100;
  totalRecords = 0;
  pageNumber = 0;

  patients: IPharmacyPatientInfo[] = [];
  filteredPatients: IPharmacyPatientInfo[] = [];
  readonly taisho = 1911;
  readonly showa = 1925;
  readonly heisei = 1988;
  readonly reiwa = 2018;
  readonly thisYear = new Date().getFullYear();
  nameOrBirthdayFormControl = new FormControl('');
  readonly dateFormats = [
    new RegExp(
      '^(?<indefiniteImperialYear>[1-9][0-9]|0?[1-9])([-/年]((?<month>1[0-2]|0?[1-9])([-/月]((?<date>3[01]|[12][0-9]|0?[1-9])日?)?)?)?)?$',
    ),
    new RegExp(
      '^(?<indefiniteImperialYear>[1-9][0-9]|0[1-9])((?<month>1[0-2]|0[1-9])(?<date>3[01]|[12][0-9]|0[1-9])?)?$',
    ),
    new RegExp(
      '^(?<year>19[0-9]{2}|2[0-9]{3})([-/年]((?<month>1[0-2]|0?[1-9])([-/月]((?<date>3[01]|[12][0-9]|0?[1-9])日?)?)?)?)?$',
    ),
    new RegExp(
      '^(?<imperialYear>[Tt](1[0-5]|0?[1-9])|[Ss](6[0-4]|[1-5][0-9]|0?[1-9])|[Hh](3[01]|[12][0-9]|0?[1-9])|[Rr]([1-9][0-9]|0?[1-9]))([-/年]((?<month>1[0-2]|0?[1-9])([-/月]((?<date>3[01]|[12][0-9]|0?[1-9])日?)?)?)?)?$',
    ),
    new RegExp('^(?<year>19[0-9]{2}|2[0-9]{3})((?<month>1[0-2]|0[1-9])(?<date>3[01]|[12][0-9]|0[1-9])?)?$'),
    new RegExp(
      '^(?<imperialYear>[Tt](1[0-5]|0?[1-9])|[Ss](6[0-4]|[1-5][0-9]|0?[1-9])|[Hh](3[01]|[12][0-9]|0?[1-9])|[Rr]([1-9][0-9]|0?[1-9]))((?<month>1[0-2]|0[1-9])(?<date>3[01]|[12][0-9]|0[1-9])?)?$',
    ),
    new RegExp('^(?<month>1[0-2]|0?[1-9])([-/月]((?<date>3[01]|[12][0-9]|0?[1-9])日?)?)?$'),
    new RegExp('^(?<month>1[0-2]|0?[1-9])(?<date>3[01]|[12][0-9]|0[1-9])?$'),
    new RegExp('^(?<date>3[01]|[12][0-9]|0?[1-9])日'),
  ];
  favoriteFilter: boolean[] = [true, true];
  favorite: string[] = ['登録', '解除済み'];
  sortType = {
    key: '',
    order: '',
  };

  getPatientName(info: IPharmacyPatientInfo) {
    return info.patient_info.family_name + info.patient_info.given_name;
  }

  getPatientNameKana(info: IPharmacyPatientInfo) {
    return info.patient_info.family_name_kana + info.patient_info.given_name_kana;
  }

  constructor(public dialog: MatDialog, private patientInfoService: PatientInfoService, private router: Router, private readonly popupCheckboxService: PopupCheckboxService) {
    this.nameOrBirthdayFormControl.valueChanges.pipe(debounceTime(300)).subscribe(_ => this.filterPatients());
  }

  async ngOnInit() {
    this.loading = true;
    try {
      await this.fetchPatientInfo();
      this.sortType.key = 'activated_date';
      this.sortType.order = 'asc';
      this.filterPatients();
    } finally {
      this.loading = false;
    }
  }

  async fetchPatientInfo() {
    this.patients = await this.patientInfoService.findAll();
  }

  private filterPatients() {
    const year = [];
    let [month, date]: (number | undefined)[] = [undefined, undefined];

    for (const dateFormat of this.dateFormats) {
      const matchResult = this.nameOrBirthdayFormControl.value
        .toString()
        .trim()
        .match(dateFormat);
      if (matchResult) {
        if (matchResult.groups.year) {
          year.push(Number(matchResult.groups.year));
        } else if (matchResult.groups.imperialYear) {
          year.push(
            Number(matchResult.groups.imperialYear.slice(1)) +
            when(matchResult.groups.imperialYear[0])
              .on(
                v => v === 'T' || v === 't',
                _ => this.taisho,
              )
              .on(
                v => v === 'S' || v === 's',
                _ => this.showa,
              )
              .on(
                v => v === 'H' || v === 'h',
                _ => this.heisei,
              )
              .on(
                v => v === 'R' || v === 'r',
                _ => this.reiwa,
              )
              .otherwise(_ => 0),
          );
        } else if (matchResult.groups.indefiniteImperialYear) {
          const indefiniteImperialYear = Number(matchResult.groups.indefiniteImperialYear);
          if (indefiniteImperialYear < 15) {
            year.push(this.taisho + indefiniteImperialYear);
          }
          if (indefiniteImperialYear < 64) {
            year.push(this.showa + indefiniteImperialYear);
          }
          if (indefiniteImperialYear < 31) {
            year.push(this.heisei + indefiniteImperialYear);
          }
          year.push(this.reiwa + indefiniteImperialYear);
        }
        month = matchResult.groups.month ? Number(matchResult.groups.month) : undefined;
        date = matchResult.groups.date ? Number(matchResult.groups.date) : undefined;
        break;
      }
    }
    this.sortPatients();
    this.filterByFavoriteSet();
    if (!year.length && !month && !date) {
      this.filterByName();
    } else {
      this.filterByBirthDate(year, month, date);
    }
  }

  private filterByName() {
    this.pageNumber = 0;
    const filters = (this.nameOrBirthdayFormControl.value as string).trim().split(' ');
    this.filteredPatients = this.filteredPatients.filter(
      p =>
        (filters.length === 1 && !filters[0]) ||
        filters.every(f => this.getPatientName(p).includes(f)) ||
        filters.every(f => this.getPatientNameKana(p).includes(f)),
    );
    this.totalRecords = this.filteredPatients.length;
  }

  private filterByBirthDate(year?: number[], month?: number, date?: number) {
    this.pageNumber = 0;

    this.filteredPatients = this.filteredPatients.filter(p => {
      const birthDate = p.patient_info.birth_date;
      return (
        (!year || year.some(y => y.toString() === format(birthDate, 'y'))) &&
        (!month || month.toString() === format(birthDate, 'M')) &&
        (!date || date.toString() === format(birthDate, 'd'))
      );
    });
    this.totalRecords = this.filteredPatients.length;
  }

  async pageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
  }

  openPatientDetails(row: IPharmacyPatientInfo) {
    this.router.navigate([`/pharmacist/patients/${row.patient_info.id}`], {
      queryParams: { isDeactivated: !!row.deactivated_at },
    });
  }

  async reloadEvent() {
    this.loading = true;
    try {
      await this.fetchPatientInfo();
      this.filterPatients();
    } finally {
      this.loading = false;
    }
  }

  async filterByFavorite() {
    this.favoriteFilter = await this.popupCheckboxService.showOverlay(
      this.favoriteHeader.elementRef,
      this.favorite,
      this.favoriteFilter,
    );
    this.loading = true;
    this.filterPatients();
    this.loading = false;
  }

  private filterByFavoriteSet() {
    this.filteredPatients = this.filteredPatients.filter(
      p => (p.deactivated_at === null && this.favoriteFilter[0]) ||
        (p.deactivated_at !== null && this.favoriteFilter[1])
    );
  }

  private sortPatients() {
    if (this.sortType.key === 'activated_date') {
      const order = this.sortType.order === 'asc' ? -1 : 1;
      this.filteredPatients = this.patients.sort((a, b) => {
        if (a.activated_at > b.activated_at) {
          return order;
        } else if (a.activated_at < b.activated_at) {
          return -order;
        } else {
          return 0;
        }
      });
    } else if (this.sortType.key === 'deactivated_date') {
      const order = this.sortType.order === 'asc' ? -1 : 1;
      this.filteredPatients = this.patients.sort((a, b) => {
        if (a.deactivated_at === null && b.deactivated_at === null) {
          return 0;
        } else if (a.deactivated_at === null) {
          return -order;
        } else if (b.deactivated_at === null) {
          return order;
        } else if (a.deactivated_at > b.deactivated_at) {
          return order;
        } else if (a.deactivated_at < b.deactivated_at) {
          return -order;
        } else {
          return 0;
        }
      });
    } else if (this.sortType.key === 'name') {
      const order = this.sortType.order === 'asc' ? -1 : 1;
      this.filteredPatients = this.patients.sort((a, b) => {
        if (a.patient_info.family_name_kana > b.patient_info.family_name_kana) {
          return -order;
        } else if (a.patient_info.family_name_kana < b.patient_info.family_name_kana) {
          return order;
        } else if (a.patient_info.given_name_kana > b.patient_info.given_name_kana) {
          return -order;
        } else if (a.patient_info.given_name_kana < b.patient_info.given_name_kana) {
          return order;
        } else {
          return 0;
        }
      });
    }
  }

  async sortData(sort: Sort) {
    this.sortType = {
      key: sort.active,
      order: sort.direction,
    };
    console.log('sort:' + this.sortType.key);
    this.filterPatients();
  }

  isMedicineNew(element: IPharmacyPatientInfo): boolean {
    return ('medicine_upload_at' in element && !('medicine_read_at' in element)) ||
      ('medicine_upload_at' in element && 'medicine_read_at' in element && element.medicine_upload_at > element.medicine_read_at);
  }
}
