<loading-spinner [loading]="loading"></loading-spinner>
<div fxLayout="column" *ngIf="!loading">
  <div class="add-button-area" fxLayoutAlign="end" fxFlexOffset="auto">
    <button class="round-button" mat-raised-button color="primary" routerLink="/pharmacy/cbf-templates/new-template">
      新規テンプレート作成
    </button>
  </div>
  <table class="table" mat-table [dataSource]="cbfTemplates">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      routerLink="/pharmacy/cbf-templates/{{ row.id }}"
      class="clickable-row"
    ></tr>
  </table>
</div>
