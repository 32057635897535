<mat-expansion-panel [expanded]="isExpanded" class="patient-info">
  <mat-expansion-panel-header>
    <mat-panel-title>患者情報</mat-panel-title>
    <mat-panel-description fxLayout="row-reverse">
      <mat-icon fxFlexAlign="center">assignment_ind</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="info-panel-content" fxLayout="column">
    <div class="info-row" fxLayout="row">
      <div fxFlex="40" class="attribute">患者氏名</div>
      <div fxFlex="60" class="value">
        <a
          class="patient-info-link-global"
          [routerLink]="'/pharmacist/patients/' + (patientInfo ? patientInfo.id : '')"
        >
          {{ patientName }}
        </a>
      </div>
    </div>
    <div class="info-row" fxLayout="row">
      <div fxFlex="40" class="attribute">患者氏名 (かな)</div>
      <div fxFlex="60" class="value">
        {{ patientNameKana }}
      </div>
    </div>
    <div class="info-row" fxLayout="row">
      <div fxFlex="40" class="attribute">性別</div>
      <div fxFlex="60" class="value">{{ patientInfo?.sex | patient_sex }}</div>
    </div>
    <div class="info-row" fxLayout="row">
      <div fxFlex="40" class="attribute">郵便番号</div>
      <div fxFlex="60" class="value">
        {{ patientInfo?.zip ? patientInfo?.zip : '' }}
      </div>
    </div>
    <div class="info-row" fxLayout="row">
      <div fxFlex="40" class="attribute">都道府県</div>
      <div fxFlex="60" class="value">
        {{ patientInfo?.prefecture ? patientInfo?.prefecture : '' }}
      </div>
    </div>
    <div class="info-row" fxLayout="row">
      <div fxFlex="40" class="attribute">住所1</div>
      <div fxFlex="60" class="value">
        {{ patientInfo?.address1 ? patientInfo?.address1 : '' }}
      </div>
    </div>
    <div class="info-row" fxLayout="row">
      <div fxFlex="40" class="attribute">住所2</div>
      <div fxFlex="60" class="value">
        {{ patientInfo?.address2 ? patientInfo?.address2 : '' }}
      </div>
    </div>
    <div class="info-row" fxLayout="row">
      <div fxFlex="40" class="attribute">住所3</div>
      <div fxFlex="60" class="value">
        {{ patientInfo?.address3 ? patientInfo?.address3 : '' }}
      </div>
    </div>
    <div class="info-row" fxLayout="row">
      <div fxFlex="40" class="attribute">電話番号</div>
      <div fxFlex="60" class="value">
        {{ patientInfo?.tel ? patientInfo?.tel : '' }}
      </div>
    </div>
  </div>
</mat-expansion-panel>
