import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { CognitoService } from './cognito.service';

@Injectable({
  providedIn: 'root',
})
export class GeoLocationService {
  constructor(private readonly cognito: CognitoService) {}

  async getGeoLocation(address: string) {
    return axios
      .get(environment.api_base_url + `pharmacy/geolocations?address=${address}`, {
        headers: {
          Authorization: await this.cognito.getAccessToken().then(accessToken => accessToken.getJwtToken()),
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if (response.data.Results.length !== 0) {
          return {
            notFound: false,
            latitude: response.data.Results[0].Place.Geometry.Point[1],
            longitude: response.data.Results[0].Place.Geometry.Point[0],
          };
        }
        return {
          notFound: true,
          latitude: 0,
          longitude: 0,
        };
      })
      .catch(error => {
        throw error;
      });
  }
}
