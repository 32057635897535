<div class="root">
  <h1>支払状況一覧</h1>
  <div fxLayout="row" class="patient-search-form-container">
    <mat-form-field fxFlex="50">
      <mat-label class="search-label">名前で検索</mat-label>
      <input matInput type="text" [formControl]="patientFilterController" />
    </mat-form-field>
  </div>
  <div class="table-container">
    <div class="paginator-and-table">
      <div class="paginator-wrapper">
        <app-custom-paginator
          class="paginator"
          [disabled]="loading"
          [length]="totalRecords"
          [pageSize]="pageSize"
          [pageIndex]="pageNumber"
          (page)="pageEvent($event)"
          (reload)="reloadEvent()"
        ></app-custom-paginator>
        <div class="paginator-overlay"></div>
      </div>
      <table
        mat-table
        [dataSource]="displayPayments"
        matSort
        matSortDisableClear="true"
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="patient_info">
          <th mat-header-cell *matHeaderCellDef>患者名</th>
          <td mat-cell *matCellDef="let element">
            <a
              color="primary"
              (click)="$event.stopPropagation()"
              [routerLink]="'/pharmacist/patients/' + element.patient_info.id"
            >
              <mat-icon matTooltip="患者詳細" matTooltipPosition="right" matTooltipShowDelay="250">
                assignment_ind</mat-icon
              >
            </a>
            {{ element.patient_info.family_name }} {{ element.patient_info.given_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="settlement_method">
          <th
            #paymentMethodHeader="cdkOverlayOrigin"
            cdk-overlay-origin
            mat-header-cell
            *matHeaderCellDef
            [style.cursor]="'pointer'"
            (click)="filterByPaymentMethod()"
          >
            決済方法
            <mat-icon>filter_alt</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.settlement_method | settlement_method }}</td>
        </ng-container>

        <ng-container matColumnDef="delivery_method">
          <th
            #deliveryMethodHeader="cdkOverlayOrigin"
            cdk-overlay-origin
            mat-header-cell
            *matHeaderCellDef
            [style.cursor]="'pointer'"
            (click)="filterByDeliveryMethod()"
          >
            配送方法
            <mat-icon>filter_alt</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.delivery_method | delivery_method }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th
            #statusHeader="cdkOverlayOrigin"
            cdk-overlay-origin
            mat-header-cell
            *matHeaderCellDef
            [style.cursor]="'pointer'"
            (click)="filterByStatus()"
          >
            決済状況
            <mat-icon>filter_alt</mat-icon>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.status | payment_status }}</td>
        </ng-container>

        <ng-container matColumnDef="insurance_covered_price">
          <th mat-header-cell *matHeaderCellDef>保険</th>
          <td class="text-right" mat-cell *matCellDef="let element">
            {{ element.settlement_amount?.insurance_covered_price | currency: 'JPY' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="insurance_uncovered_price">
          <th mat-header-cell *matHeaderCellDef>保険外</th>
          <td class="text-right" mat-cell *matCellDef="let element">
            {{ element.settlement_amount?.insurance_uncovered_price | currency: 'JPY' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="shipping_fee">
          <th mat-header-cell *matHeaderCellDef>送料</th>
          <td class="text-right" mat-cell *matCellDef="let element">
            {{ element.settlement_amount?.shipping_fee | currency: 'JPY' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="created_at">服薬指導完了日時</th>
          <td mat-cell *matCellDef="let element">
            {{ element.created_at | date: 'yyyy/MM/dd HH:mm' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="completed_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="completed_date">決済日時</th>
          <td mat-cell *matCellDef="let element">
            {{
              element.payment_completed_date === 0 ? '' : (element.payment_completed_date | date: 'yyyy/MM/dd HH:mm')
            }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          class="clickable"
          [class.unclickable]="paymentsDisabled[i]"
          routerLink="/pharmacist/payments/{{ row.id }}"
          *matRowDef="let row; columns: displayedColumns; index as i"
        ></tr>
      </table>
    </div>
    <div *ngIf="loading" class="loading-shade">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
