<h2 mat-dialog-title>グループ名の設定</h2>
<mat-dialog-content fxLayout="column">
  <mat-form-field>
    <mat-label>グループ名を入力</mat-label>
    <input matInput type="text" [formControl]="nameFormControl" />
    <mat-error *ngIf="nameFormControl.hasError('pattern')">グループ名に \ 記号を含めることはできません</mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
  <button mat-button mat-dialog-close><mat-icon>close</mat-icon>閉じる</button>
  <button
    mat-button
    color="primary"
    (click)="confirm()"
    [disabled]="nameFormControl.hasError('required')||nameFormControl.hasError('pattern')"
  >
    <mat-icon>check</mat-icon>
    決定
  </button>
</mat-dialog-actions>
