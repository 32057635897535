import { Injectable } from '@angular/core';
import { IReservationSlot } from 'src/models';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';
import { ITimeframe } from 'src/models/reservation-slot';

@Injectable({
  providedIn: 'root',
})
export class ReservationSlotService extends ServiceBase<IReservationSlot> {
  readonly propertyName = 'reservations';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'reservation_slots');
  }

  public findAll(params?: { limit?: number }): Promise<IReservationSlot[]> {
    return super.findAll(params);
  }

  public fetchDefaults(): Promise<IReservationSlot[]> {
    return super.findAll({ is_default: true });
  }

  public findAllWithPagination(params?: { limit?: number; last_key?: string }) {
    return super.findAllWithPagination({ ...params, is_default: false }).then(result => ({
      [this.propertyName]: (result[this.propertyName] ?? []) as IReservationSlot[],
      pagination: result.pagination,
    }));
  }

  public saveDefault(id: string | null, pharmacyId: string, timeframes: ITimeframe[], holidayCheck?: boolean) {
    if (id === null) {
      return super.create({
        pharmacy_id: pharmacyId,
        name: 'デフォルト予約可能枠',
        priority: -1,
        timeframes,
        holiday_check: holidayCheck,
      });
    } else {
      return super.update({ id, timeframes, holiday_check: holidayCheck });
    }
  }
}
