<div class="reservation-list">
  <h1>服薬指導予約一覧</h1>
  <form>
    <div class="form-div" fxLayout="row wrap" fxLayoutAlign="space-between">
      <mat-form-field>
        <mat-label class="search-label" for="name">名前で検索</mat-label>
        <input type="text" matInput name="name" [(ngModel)]="name" />
      </mat-form-field>
      <div class="toggle-buttons">
        <mat-button-toggle-group
          [value]="viewType"
          (change)="toggleViewType(group.value)"
          #group="matButtonToggleGroup"
        >
          <mat-button-toggle value="calendar" aria-label="カレンダー">
            カレンダー表示<mat-icon>calendar_today</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="list" aria-label="リスト">リスト表示<mat-icon>list</mat-icon> </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </form>

  <list *ngIf="viewType === 'list'" [nameFilter]="name"></list>
  <calendar *ngIf="viewType === 'calendar'" [nameFilter]="name"></calendar>
</div>
