import { Route } from '@angular/compiler/src/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { when } from 'src/app/modules/when';
import { IPatientInfo } from 'src/models';
import { IPCF } from 'src/models/pcf';
import { IPCFTemplate } from 'src/models/pcf-template';
import { PCFTemplateService } from 'src/services/api/pcf-template.service';
import { PcfService } from 'src/services/api/pcf.service';
import { NotificationService } from 'src/services/notification.service';

@Component({
  selector: 'app-follow-base-select',
  templateUrl: './follow-base-select.component.html',
  styleUrls: ['./follow-base-select.component.scss'],
})
export class FollowBaseSelectComponent implements OnInit, OnDestroy {
  follows: IPCF[] = [];
  patientInfoId = '';
  templates: IPCFTemplate[] = [];
  templateGroups: { [x: string]: IPCFTemplate[] } = {};

  readonly followsPageSize = 100;
  followsTotalRecords = 0;
  followsPageNumber = 0;
  followsLastKeys: string[] = [];

  followsFetching = false;
  templatesFetching = false;
  notificationSubscription?: Subscription;

  get templateGroupNames() {
    return Object.keys(this.templateGroups).sort((a, b) =>
      when([a, b])
        .on(
          v => v[0] === 'その他',
          _ => 1,
        )
        .on(
          v => v[1] === 'その他',
          _ => -1,
        )
        .otherwise(_ => (a < b ? -1 : 1)),
    );
  }

  constructor(
    private readonly pcfTemplateService: PCFTemplateService,
    private readonly pcfService: PcfService,
    private readonly notificationService: NotificationService,
    private readonly route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    this.patientInfoId = await this.route.queryParams
      .pipe(
        first(),
        map(p => (p.patientInfoId ?? '').toString()),
      )
      .toPromise();
    const init = async () => {
      this.followsFetching = true;
      this.templatesFetching = true;
      try {
        await Promise.all([this.fetchFollows(), this.fetchTemplates()]);
      } finally {
        this.followsFetching = false;
        this.templatesFetching = false;
      }
    };
    this.notificationSubscription = this.notificationService.messageReceive.subscribe(async _ => await init());
  }

  ngOnDestroy() {
    this.notificationSubscription?.unsubscribe();
  }

  fetchFollows(params?: { last_key?: string }) {
    return this.pcfService
      .findAllWithPagination({ ...params, limit: this.followsPageSize })
      .then(result => {
        this.followsTotalRecords = result.pagination.totalrecords;
        if (result.pagination.last_key && this.followsPageNumber >= this.followsLastKeys.length) {
          this.followsLastKeys.push(result.pagination.last_key);
        }
        this.follows = result.pcfs;
      })
      .catch(error => {
        throw error;
      });
  }

  async fetchTemplates() {
    this.templateGroups = { その他: [] };
    await this.pcfTemplateService.findAll().then(result =>
      result.forEach(t => {
        if (t.name?.includes('\\')) {
          const folder = t.name?.split('\\', 2)[0];
          t.name = t.name?.split('\\', 2)[1];
          if (this.templateGroups[folder] !== undefined) {
            this.templateGroups[folder].push(t);
          } else {
            this.templateGroups[folder] = [t];
          }
        } else {
          this.templateGroups.その他.push(t);
        }
      }),
    );
  }

  async followsPageEvent(event: PageEvent) {
    this.followsPageNumber = event.pageIndex;
    this.followsFetching = true;
    try {
      await this.fetchFollows({
        last_key: this.followsPageNumber > 0 ? this.followsLastKeys[this.followsPageNumber - 1] : undefined,
      });
    } finally {
      this.followsFetching = false;
    }
  }

  async followsReloadEvent() {
    this.followsFetching = true;
    this.followsPageNumber = 0;
    this.followsLastKeys = [];
    try {
      await this.fetchFollows();
    } finally {
      this.followsFetching = false;
    }
  }

  async templatesReloadEvent() {
    this.templatesFetching = true;
    try {
      await this.fetchTemplates();
    } finally {
      this.templatesFetching = false;
    }
  }

  getPatientName(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name ?? '') + (patientInfo?.given_name ?? '');
  }

  getPatientNameKana(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name_kana ?? '') + (patientInfo?.given_name_kana ?? '');
  }
}
