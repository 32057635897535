import { IEntityBase } from './entity-base';
import { IPatientInfo } from './patient-info';
import { IPharmacy } from './pharmacy';
import { IQAT, QuestionType } from './qa-template';

export interface IPrescription extends IEntityBase {
  id: string;
  patient_account_id: string;
  patient_info_id: string;
  patient_info?: IPatientInfo;
  pharmacy_id: string;
  pharmacy?: IPharmacy;
  message?: string;
  status: PrescriptionStatus;
  status_reason?: string;
  mq_questions?: { type: QuestionType; question: string; answers: string[]; required: boolean }[];
  mq_answers?: { type: QuestionType; question: string; answers: string[] }[];
  uploads: { id: string; images: string[]; created_at: number; updated_at: number }[];
  created_at: number;
  updated_at: number;
  confirmed_at?: number;
  rejected_at?: number;
  req_resend_at?: number;
  dispensed_at?: number;
  completed_at?: number;
  cancelled_at?: number;
  pharmacist_confirmed_at?: number;
}

export enum PrescriptionStatus {
  create_new = 'create_new',
  sent = 'sent',
  confirmed = 'confirmed',
  dispensed = 'dispensed',
  req_resend = 'req_resend',
  rejected = 'rejected',
  completed = 'completed',
  conf_cancelled = 'conf_cancelled',
  nconf_cancelled = 'nconf_cancelled',
}

export class Prescription implements IPrescription {
  id: string;
  patient_account_id: string;
  patient_info_id: string;
  patient_info?: IPatientInfo;
  pharmacy_id: string;
  pharmacy?: IPharmacy;
  message?: string;
  status: PrescriptionStatus;
  status_reason?: string;
  mq_questions?: { type: QuestionType; question: string; answers: string[]; required: boolean; }[];
  mq_answers?: { type: QuestionType; question: string; answers: string[]; }[];
  uploads: { id: string; images: string[]; created_at: number; updated_at: number; }[];
  created_at: number;
  updated_at: number;
  confirmed_at?: number;
  rejected_at?: number;
  req_resend_at?: number;
  dispensed_at?: number;
  completed_at?: number;
  createdId?: string;
  createdAt?: string;
  modifiedId?: string;
  modifiedAt?: string;

  constructor(prescription: IPrescription) {
    this.id = prescription.id;
    this.patient_account_id = prescription.patient_account_id;
    this.patient_info_id = prescription.patient_info_id;
    this.patient_info = prescription.patient_info;
    this.pharmacy_id = prescription.pharmacy_id;
    this.pharmacy = prescription.pharmacy;
    this.message = prescription.message;
    this.status = prescription.status;
    this.status_reason = prescription.status_reason;
    this.mq_questions = prescription.mq_questions;  //{ type: QuestionType; question: string; answers: string[]; required: boolean; }[] | undefined;
    this.mq_answers = prescription.mq_answers; //?: { type: QuestionType; question: string; answers: string[]; }[] | undefined;
    this.uploads = prescription.uploads; //: { id: string; images: string[]; created_at: number; updated_at: number; }[];
    this.created_at = prescription.created_at;
    this.updated_at = prescription.updated_at
    this.confirmed_at = prescription.confirmed_at;
    this.rejected_at = prescription.rejected_at;
    this.req_resend_at = prescription.req_resend_at;
    this.dispensed_at = prescription.dispensed_at;
    this.completed_at = prescription.completed_at;
    this.createdId = prescription.createdId;
    this.createdAt = prescription.createdAt;
    this.modifiedId = prescription.modifiedId;
    this.modifiedAt = prescription.modifiedAt;

  }

}
