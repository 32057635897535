<div class="root" fxLayout="column">
  <h1>服薬指導予約可能枠設定</h1>
  <div class="top-area" fxLayout="row-reverse">
    <!-- <loading-button
      [name]="'新規作成'"
      [color]="'primary'"
      [loading]="loading"
      routerLink="./detail"
      icon="add"
    ></loading-button> -->
    <div fxFlexAlign="center" fxLayout="row" fxLayoutGap="15px">
      <div fxFlexAlign="center">
        現在の予約可能枠幅は
        <strong>「{{ pharmacy?.pci_slot_interval }}分」</strong>
        です。
      </div>
      <button class="round-button" color="primary" mat-raised-button routerLink="/pharmacy/profile">
        <mat-icon>calendar_today</mat-icon>設定変更
      </button>
    </div>
  </div>
  <mat-expansion-panel class="default-expansion-panel">
    <mat-expansion-panel-header>
      <mat-panel-title>
        デフォルト設定
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="holiday-check-area">
      <mat-checkbox [(ngModel)]="holidayCheck">国民の祝日は予約不可とする</mat-checkbox>
    </div>
    <div class="default-form" *ngFor="let controls of defaultFormControls; index as day">
      <h4>{{ days[day] }}</h4>
      <div class="slider-area" fxLayout="column">
        <div *ngFor="let control of controls; index as i" fxFlex fxLayout="row">
          <ngx-slider class="slider" [formControl]="defaultFormControls[day][i]" [options]="options"></ngx-slider>

          <button
            mat-stroked-button
            fxFlexAlign="center"
            (click)="deleteDefaultReservationSlot(day, i)"
            class="delete-button"
            [disabled]="i === 0"
          >
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
              <mat-icon>remove_circle_outline</mat-icon>
              <div>削除</div>
            </div>
          </button>
        </div>
      </div>

      <div class="button-area">
        <button mat-stroked-button fxFlexAlign="center" (click)="addDefaultReservationSlot(day)" class="add-button">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
            <mat-icon>add_circle_outline</mat-icon>
            <div>追加</div>
          </div>
        </button>
      </div>
    </div>

    <loading-button
      class="save-button"
      name="保存"
      [color]="'primary'"
      [loading]="defaultSaving"
      (click)="saveDefault()"
      icon="save"
    ></loading-button>
  </mat-expansion-panel>
  <div class="table-container">
    <div class="top-area" fxLayout="row-reverse">
      <loading-button
        [name]="'新規作成'"
        [color]="'primary'"
        [loading]="loading"
        routerLink="./detail"
        icon="add"
      ></loading-button>
    </div>
    <div class="paginator-and-table">
      <app-custom-paginator
        [disabled]="loading"
        [length]="totalRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageNumber"
        (page)="pageEvent($event)"
        (reload)="reloadEvent()"
      ></app-custom-paginator>
      <div class="table" fxLayout="column">
        <mat-card class="header" fxLayout="row">
          <div fxFlex="75">名前</div>
          <div fxFlex="25">優先度</div>
        </mat-card>
        <mat-card *ngFor="let slot of reservationSlots" class="element" fxLayout="row" (click)="toDetailPage(slot.id)">
          <div fxFlex="75">{{ slot.name }}</div>
          <div fxFlex="25">{{ slot.priority }}</div>
        </mat-card>
      </div>
    </div>
    <div class="loading-shade" *ngIf="loading"><mat-spinner></mat-spinner></div>
  </div>
</div>
