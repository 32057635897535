import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPrescription } from 'src/models';
import { CognitoService } from '../cognito.service';
import { ServiceBase } from '../service-base';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionInfoService extends ServiceBase<IPrescription> {
  propertyName = 'prescription_infos';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'prescriptions/info');
  }

  async findAll(pharmacy_id: string) {
    const params = {
      pharmacy_id,
    };
    return super.findAll(params);
  }
}
