import { Injectable } from '@angular/core';
import { IPCITemplate } from 'src/models';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PCITemplateService extends ServiceBase<IPCITemplate> {
  readonly propertyName = 'pci_templates';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'pci_templates');
  }

  public findAll(params?: { limit?: number }): Promise<IPCITemplate[]> {
    return super.findAll(params);
  }

  public findAllWithPagination(params: { status?: string; limit?: number; last_key?: string } = { limit: 25 }) {
    return super.findAllWithPagination(params).then(result => ({
      [this.propertyName]: (result[this.propertyName] ?? []) as IPCITemplate[],
      pagination: result.pagination as { totalrecords: number; displayrecords: number; last_key: string },
    }));
  }
}
