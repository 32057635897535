import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import axios, { AxiosRequestConfig } from 'axios';
import { environment } from 'src/environments/environment';
import { IPCI, IPCITemplate } from 'src/models';
import { Delivery, DeliveryForPost } from 'src/models/pci';
import { IQAT } from 'src/models/qa-template';
import { CognitoService } from '../cognito.service';
import { ServiceBase } from '../service-base';

@Injectable({
  providedIn: 'root',
})
export class PciService extends ServiceBase<IPCI> {
  readonly propertyName = 'pcis';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'pcis');
  }

  public findAll(params?: { status?: string; limit?: number; last_key?: string }): Promise<IPCI[]> {
    return super.findAll(params);
  }

  public findAllWithPagination(params: { status?: string; limit?: number; last_key?: string } = { limit: 25 }) {
    return super.findAllWithPagination(params).then(result => ({
      [this.propertyName]: (result[this.propertyName] ?? []) as IPCI[],
      pagination: result.pagination as { totalrecords: number; displayrecords: number; last_key?: string },
    }));
  }

  public specifyPciTemplate(pci: IPCI, pciTemplate: IPCITemplate) {
    const updatePci: IPCI = {
      id: pci.id,
      pci_questions_template_id: pciTemplate.id,
      pci_questions: pciTemplate.qa,
    };
    return super.update(updatePci);
  }

  public updatePciAnswer(pci: IPCI, pciAnswers: IQAT[]) {
    const updatePci: IPCI = {
      id: pci.id,
      pci_answers: pciAnswers,
    };
    return super.update(updatePci);
  }

  public async complete(pciId: string, config?: AxiosRequestConfig) {
    const baseUrl = environment.api_base_url;
    await axios.post(`${baseUrl}pharmacist/pcis/${pciId}/complete`, undefined, await this.buildConfig(config));
  }

  public async confirm(pciId: string, timeFrameId: number | null, config?: AxiosRequestConfig) {
    if (timeFrameId === null) {
      throw new Error('時間を選択してください。');
    }
    const baseUrl = environment.api_base_url;
    await axios.post(
      `${baseUrl}pharmacist/pcis/${pciId}/confirm`,
      { timeframe_id: timeFrameId },
      await this.buildConfig(config),
    );
  }

  public async decline(pciId: string, config?: AxiosRequestConfig) {
    const baseUrl = environment.api_base_url;
    await axios.post(`${baseUrl}pharmacist/pcis/${pciId}/decline`, undefined, await this.buildConfig(config));
  }

  public async cancel(pciId: string, config?: AxiosRequestConfig) {
    const baseUrl = environment.api_base_url;
    await axios.post(`${baseUrl}pharmacist/pcis/${pciId}/cancel`, undefined, await this.buildConfig(config));
  }

  public async startMeeting(pciId: string, config?: AxiosRequestConfig) {
    return await axios.post(
      environment.api_base_url + `pharmacist/pcis/${pciId}/start_meeting`,
      undefined,
      await this.buildConfig(config),
    );
  }

  public async endMeeting(pciId: string, config?: AxiosRequestConfig) {
    return await axios
      .post(
        environment.api_base_url + `pharmacist/pcis/${pciId}/end_meeting`,
        undefined,
        await this.buildConfig(config),
      )
      .catch(e => {
        console.log('pciServiceError', e);
        return e;
      });
  }

  public async completeMeeting(pciId: string, config?: AxiosRequestConfig) {
    await axios.post(
      environment.api_base_url + `pharmacist/pcis/${pciId}/complete_meeting`,
      undefined,
      await this.buildConfig(config),
    );
  }

  public async incompleteMeeting(pciId: string, config?: AxiosRequestConfig) {
    await axios.post(
      environment.api_base_url + `pharmacist/pcis/${pciId}/incomplete`,
      undefined,
      await this.buildConfig(config),
    );
  }

  public async forceEndMeeting(pciId: string, config?: AxiosRequestConfig) {
    await axios.post(
      environment.api_base_url + `pharmacist/pcis/${pciId}/force_end_meeting`,
      undefined,
      await this.buildConfig(config),
    );
  }

  // 配送関連
  public async postDeliveryInfo(pciId: string, delivery: DeliveryForPost, config?: AxiosRequestConfig) {
    await axios.post(
      environment.api_base_url + `pharmacist/pcis/${pciId}/delivery`,
      delivery,
      await this.buildConfig(config),
    );
  }
  public async shipped(pciId: string, config?: AxiosRequestConfig) {
    await axios.post(
      environment.api_base_url + `pharmacist/pcis/${pciId}/delivery/shipped`,
      undefined,
      await this.buildConfig(config),
    );
  }
  public async unshipped(pciId: string, config?: AxiosRequestConfig) {
    await axios.post(
      environment.api_base_url + `pharmacist/pcis/${pciId}/delivery/unshipped`,
      undefined,
      await this.buildConfig(config),
    );
  }
}
