import { Injectable } from '@angular/core';
import { IPharmacist } from 'src/models';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';
import axios, { AxiosRequestConfig } from 'axios';
import { environment } from 'src/environments/environment';
import { ICall } from 'src/models/call';

@Injectable({
  providedIn: 'root',
})
export class CallService extends ServiceBase<ICall> {
  propertyName = 'calls';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'calls');
  }

  start(data: { id: string; pharmacy_id: string; pharmacist_id: string; patient_info_id: string; patient_id: string }) {
    return super.create(data);
  }

  getStatus(callId: string) {
    return super.find(callId);
  }

  async cancel(callId: string, config: AxiosRequestConfig) {
    return await axios.put(environment.api_base_url + `pharmacist/calls/${callId}/cancel`, undefined, config);
  }
}
