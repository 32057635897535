import { Injectable } from '@angular/core';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';
import { GmoSetting } from 'src/models/gmo-setting';
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class PaymentSettingService extends ServiceBase<GmoSetting> {
  propertyName = 'gmo_settings';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'gmo_settings');
  }

  public async getSetting(offset?: number, limit?: number, patient_account_id?: string): Promise<GmoSetting> {
    const params = {
      offset,
      limit,
      patient_account_id,
    };
    return ((await super.findAll(params)) as unknown) as GmoSetting;
  }
}
