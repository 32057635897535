import { animate, style, transition, trigger } from '@angular/animations';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { first } from 'rxjs/operators';

export const CHECKBOX_DATA = new InjectionToken<{
  names: string[];
  initialState: boolean[];
  title?: string;
  icon?: string;
}>('CHECKBOX_NAMES_AND_INITS');

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.2s 0s ease-in-out', style({ opacity: 1 }))]),
    ]),
  ],
})
export class CheckboxListComponent {
  names: string[] = [];
  state: boolean[] = [];
  title = '';
  icon = '';
  constructor(
    @Inject(CHECKBOX_DATA) data: { names: string[]; initialState: boolean[]; title?: string; icon?: string },
  ) {
    this.names = Array.from(data.names);
    this.state = Array.from(data.initialState);
    this.title = data.title ?? '';
    this.icon = data.icon ?? '';
  }
}

@Injectable({
  providedIn: 'root',
})
export class PopupCheckboxService {
  constructor(private readonly parentInjector: Injector, private readonly overlay: Overlay) {}
  async showOverlay(
    overlayOrigin: ElementRef,
    names: string[],
    initState?: boolean[],
    title?: string,
    icon?: string,
    config?: {
      width?: string;
      height?: string;
      backdropClass?: string[];
      originX?: 'start' | 'end' | 'center';
      originY?: 'top' | 'bottom' | 'center';
      overlayX?: 'start' | 'end' | 'center';
      overlayY?: 'top' | 'bottom' | 'center';
    },
  ) {
    const initialState = names.map(_ => false);
    (initState ?? []).slice(0, names.length).forEach((s, i) => (initialState[i] = s));
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(overlayOrigin)
      .withPositions([
        {
          originX: config?.originX ?? 'end',
          originY: config?.originY ?? 'bottom',
          overlayX: config?.overlayX ?? 'end',
          overlayY: config?.overlayY ?? 'top',
        },
      ]);
    const controllerRef = this.overlay.create({
      scrollStrategy,
      positionStrategy,
      width: config?.width ?? 'auto',
      height: config?.height ?? 'auto',
      hasBackdrop: true,
      backdropClass: config?.backdropClass ?? ['checkbox-backdrop'],
    });
    const injector = Injector.create({
      parent: this.parentInjector,
      providers: [{ provide: CHECKBOX_DATA, useValue: { names, initialState, title, icon } }],
    });
    const componentRef = controllerRef.attach(new ComponentPortal(CheckboxListComponent, null, injector));
    await controllerRef
      .backdropClick()
      .pipe(first())
      .toPromise();
    controllerRef.dispose();
    return componentRef.instance.state;
  }
}
