import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPatientInfo, IPharmacist, IPrescription } from 'src/models';
import { ICertificate } from 'src/models/health-ins-card';
import { ImagePath, IPharmacyPatientInfo } from 'src/models/patient-info';
import { Pcf } from 'src/models/pcf';
import { Pci } from 'src/models/pci';
import { Prescription } from 'src/models/prescription';
import { PrescriptionStatusPipe } from 'src/pipes/prescription_status.pipe';
import { PatientInfoService } from 'src/services/api/patient-info.service';
import { PcfService } from 'src/services/api/pcf.service';
import { PciService } from 'src/services/api/pci.service';
import { PrescriptionService } from 'src/services/api/prescription.service';
import { CognitoService } from 'src/services/cognito.service';

@Component({
  selector: 'app-patients-detail',
  templateUrl: './patients-detail.component.html',
  styleUrls: ['./patients-detail.component.scss'],
})
export class PatientsDetailComponent implements OnInit {
  patientInfo: IPatientInfo | null = null;
  pharmacyPatientInfo: IPharmacyPatientInfo | null = null;
  reservations: Pci[] = [];
  pharmacists: IPharmacist[] = [];
  pcfs: Pcf[] = [];
  prescriptions: IPrescription[] = [];
  loading = true;
  paths: ImagePath[] = [];
  isDeleted = false;
  isDeactivated = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private patientInfoService: PatientInfoService,
    private pciService: PciService,
    private cognito: CognitoService,
    private pcfService: PcfService,
    private readonly prescriptionService: PrescriptionService,
  ) { }

  async ngOnInit() {
    try {
      const patientInfoId = this.route.snapshot.paramMap.get('patientInfoId');
      this.patientInfo = await this.patientInfoService.find(patientInfoId ?? '');
      if (!this.patientInfo) {
        this.isDeleted = true;
        //throw new Error('患者情報が存在しません。');
      }
      if (this.patientInfo && this.patientInfo.deleted_at) {
        this.isDeleted = true;
      }
      const config = await this.config();
      this.pharmacyPatientInfo = await this.patientInfoService.findPharmacyPatientInfo(patientInfoId ?? '', config);
      if (!this.pharmacyPatientInfo) {
        throw new Error('当薬局にお気に入り薬局の登録がありません。');
      }

      //this.isDeactivated = this.route.snapshot.queryParamMap.get('isDeactivated') === 'true';
      this.isDeactivated = this.pharmacyPatientInfo.deactivated_at ? true : false;

      this.reservations = (await this.pciService.findAll())
        .map(pci => new Pci(pci))
        .filter(pci => pci.patient_info_id === patientInfoId)
        .filter(pci => 0 < pci.time_frames.length)
        .filter(pci => pci.startTimeOfFirstTimeFrame !== undefined)
        // filterでpci.startTimeOfFirstTimeFrameがundefinedにならないことを確認済み
        // tslint:disable-next-line
        .sort((a, b) => b.startTimeOfFirstTimeFrame! - a.startTimeOfFirstTimeFrame!);

      this.pcfs = (await this.pcfService.findAll())
        .filter(pcf => pcf.patient_info_id === patientInfoId)
        .map(pcf => new Pcf(pcf))
        .filter(pcf => pcf.send_at !== undefined)
        // filterでpci.send_atがundefinedにならないことを確認済み
        // tslint:disable-next-line
        .sort((a, b) => b.send_at! - a.send_at!);

      this.prescriptions = (await this.prescriptionService.findAll())
        .filter(prescription => prescription.patient_info_id === patientInfoId)
        .map(prescription => new Prescription(prescription))
        .filter(prescription => prescription.created_at !== undefined)
        // filterでpci.send_atがundefinedにならないことを確認済み
        // tslint:disable-next-line
        .sort((a, b) => b.created_at! - a.created_at!);

      const [certificatePaths, insurancePath] = await Promise.all([
        this.fetchPublicBeneficiaryCertificates(),
        this.fetchInsuranceCard(),
      ]);
      this.paths = [insurancePath ?? { path: '' }, ...certificatePaths].filter(str => str.path !== '');
    } finally {
      this.loading = false;
    }
  }

  private async fetchPublicBeneficiaryCertificates(): Promise<ImagePath[]> {
    if (this.patientInfo === null) {
      return [];
      //throw new Error('患者情報が存在しないため、証明書の取得ができません。');
    }
    if (!this.patientInfo.certificates) {
      return [];
    }
    const patientInfoId = this.patientInfo.id;
    const config = await this.config();
    const promises = this.patientInfo.certificates.map(async c =>
      this.patientInfoService.getPublicBeneficiaryCertificates(patientInfoId, c.key, config).catch(res => {
        if (res.response.status === 404) {
          return null;
        }
        throw new Error('公費受給者証の取得ができませんでした。');
      }),
    );
    return (await Promise.all(promises))
      .filter(r => r)
      .map(r => r as ICertificate)
      .map(r => ({ path: 'data:image/jpeg;base64,' + r.image }));
  }

  private async fetchInsuranceCard(): Promise<ImagePath | null> {
    if (this.patientInfo === null) {
      return null;
      //throw new Error('患者情報が存在しないため、保険証の取得ができません。');
    }
    const response = await this.patientInfoService
      .getInsuranceCard(this.patientInfo.id, await this.config())
      .catch(res => {
        if (res.response.status === 404) {
          return null;
        }
        throw new Error('保険証画像が取得できませんでした。');
      });
    if (response) {
      return { path: 'data:image/jpeg;base64,' + response.image };
    }
    return null;
  }

  createFollow() {
    this.router.navigate(['/pharmacist/follow/base-select'], {
      queryParams: { patientInfoId: this.patientInfo?.id ?? '' },
    });
  }

  createEmptyFollow() {
    this.router.navigate(['/pharmacist/follow/new'], {
      queryParams: { patientInfoId: this.patientInfo?.id ?? '' },
    });
  }

  openAlarmData() {
    this.router.navigate([`/pharmacist/alarm-data/${this.patientInfo?.id}`]);
  }

  private async config() {
    return {
      headers: {
        Authorization: (await this.cognito.getAccessToken()).getJwtToken(),
        'Content-Type': 'application/json',
      },
    };
  }

  getPatientName(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name ?? '') + (patientInfo?.given_name ?? '');
  }

  get haveAlarmData(): boolean {
    return this.pharmacyPatientInfo?.medicine_upload_at != null;
  }
}
