<div class="root">
  <h1>問診票テンプレート</h1>
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row-reverse">
      <loading-button
        [name]="'保存'"
        [disabled]="loading"
        [color]="'primary'"
        [loading]="loading"
        (click)="saveTemplate()"
        icon="save"
      ></loading-button>
    </div>
    <app-questionnaire-editor
      [(questions)]="questions"
      [disabled]="loading"
      [isVisibleRequiredCheckBox]="true"
    ></app-questionnaire-editor>
  </div>
</div>
