<div class="overlay-root" [@fadeInAnimation]="">
  <div
    class="news-card"
    *ngFor="let news of data$ | async"
    fxLayout="row"
    fxLayoutGap="20px"
    [routerLink]="news.routerLink"
    (click)="onclick()"
  >
    <mat-icon fxFlexAlign="center">{{ icon(news.type) }}</mat-icon>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="15px">
        <div *ngIf="news.date as date">{{ date | date: 'yyyy/MM/dd HH:mm:ss' }}</div>
        <div>{{ sliceString(news.patientName, 8) }}</div>
      </div>
      <div>{{ sliceString(news.title, 25) }}</div>
    </div>
  </div>
  <div class="no-news-card" *ngIf="!(data$ | async)!.length">
    新着情報はありません
  </div>
</div>
