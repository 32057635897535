import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IPCF } from 'src/models/pcf';
import { PcfService } from 'src/services/api/pcf.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { when } from 'src/app/modules/when';
import { PageEvent } from '@angular/material/paginator';
import { IPatientInfo, IPharmacist } from 'src/models';
import { PopupCheckboxService } from 'src/app/parts/checkbox-list/checkbox-list.component';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/services/notification.service';
import { PharmacistService } from 'src/services/api/pharmacist.service';

type followStatus = '不明' | '確認済み' | '未確認' | '回答待ち（未読）' | '回答待ち（既読）' | '送信予約済み' | 'キャンセル済み';
@Component({
  selector: 'app-follow-list',
  templateUrl: './follow-list.component.html',
  styleUrls: ['./follow-list.component.scss'],
})
export class FollowListComponent implements OnInit, OnDestroy {
  @ViewChild('stateFilterControllerOrigin') stateFilterControllerOrigin!: CdkOverlayOrigin;
  loading = true;
  followsForDisplay: PCFForDisplay[] = [];
  followsDisabled: boolean[] = [];
  patientFilterControl = new FormControl('', []);
  sortConfig: [-1 | 0 | 1, -1 | 0 | 1] = [0, 0];
  status = ['unsent', 'sent', 'read', 'answered', 'confirmed', 'cancelled'];
  statusFilter = [true, true, true, true, true, false];
  totalRecords = 0;
  pageNumber = 0;
  readonly pageSize = 100;
  private lastKeys: string[] = [];
  readonly columnProportion: number[] = [20, 15, 15, 20, 15, 15];
  readonly columnProportionLtMd: number[] = [27, 23, 23, 27];
  readonly timeDisplayFormat = 'yyyy/MM/dd HH:mm';
  patientFilterControlSubscription: Subscription;
  notificationSubscription?: Subscription;
  private pharmacists: IPharmacist[] = [];
  reloadEventId: NodeJS.Timer | null = null;

  private get statusFilterString() {
    return this.statusFilter
      .reduce((acc: string, cur, index) => (cur ? acc + this.status[index] + ',' : acc), '')
      .slice(0, -1);
  }

  constructor(
    private readonly pcfService: PcfService,
    private readonly popupCheckboxService: PopupCheckboxService,
    private readonly dialog: MatDialog,
    private readonly pharmacistsService: PharmacistService,
    private readonly notificationService: NotificationService,
  ) {
    this.patientFilterControlSubscription = this.patientFilterControl.valueChanges.subscribe(_ => this.filterByName());
  }

  async ngOnInit(): Promise<void> {
    const init = async () => {
      this.loading = true;
      try {
        this.pharmacists = await this.fetchPharmacists();
        this.followsForDisplay = await this.fetchFollows({ status: this.statusFilterString });
      } finally {
        this.loading = false;
      }
    };
    this.notificationSubscription = this.notificationService.messageReceive.subscribe(async _ => await init());
    await init();
    this.reloadEventId = setInterval(async () => {
      this.loading = true;
      try {
        await this.fetchFollows({
          status: this.statusFilterString,
          last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
        });
        this.filterByName();
      } finally {
        this.loading = false;
      }
    }, 300000);
  }

  ngOnDestroy() {
    this.patientFilterControlSubscription.unsubscribe();
    this.notificationSubscription?.unsubscribe();
    if (this.reloadEventId) {
      clearInterval(this.reloadEventId);
    }
    this.reloadEventId = null;
  }

  private fetchPharmacists() {
    return this.pharmacistsService.findAll();
  }

  async fetchFollows(params?: { status?: string; last_key?: string }): Promise<PCFForDisplay[]> {
    return this.pcfService.findAllWithPagination({ ...params, limit: this.pageSize }).then(result => {
      // this.followsForDisplay = result.pcfs.map(follow => {
      this.totalRecords = result.pagination.totalrecords;
      if (result.pagination.last_key && this.pageNumber >= this.lastKeys.length) {
        this.lastKeys.push(result.pagination.last_key);
      }
      return result.pcfs.map(follow => {
        return {
          ...follow,
          status: when(follow)
            .on<followStatus>(
              f => f.confirmed_at !== null && f.confirmed_at !== undefined,
              _ => '確認済み',
            )
            .on<followStatus>(
              f => f.cancelled_at !== null && f.cancelled_at !== undefined,
              _ => 'キャンセル済み',
            )
            .on<followStatus>(
              f =>
                f.answered_at !== null &&
                f.answered_at !== undefined &&
                (f.confirmed_at === null || f.confirmed_at === undefined),
              _ => '未確認',
            )
            .on<followStatus>(
              f =>
                f.sent_at !== null &&
                f.sent_at !== undefined &&
                (f.first_read_at === null || f.first_read_at === undefined) &&
                (f.answered_at === null || f.answered_at === undefined) &&
                (f.confirmed_at === null || f.confirmed_at === undefined) &&
                (f.cancelled_at === null || f.cancelled_at === undefined),
              _ => '回答待ち（未読）',
            )
            .on<followStatus>(
              f =>
                f.sent_at !== null &&
                f.sent_at !== undefined &&
                f.first_read_at !== null && f.first_read_at !== undefined &&
                (f.answered_at === null || f.answered_at === undefined) &&
                (f.confirmed_at === null || f.confirmed_at === undefined) &&
                (f.cancelled_at === null || f.cancelled_at === undefined),
              _ => '回答待ち（既読）',
            )
            .on<followStatus>(
              f =>
                f.send_at !== null &&
                f.send_at !== undefined &&
                (f.sent_at === null || f.sent_at === undefined) &&
                (f.answered_at === null || f.answered_at === undefined) &&
                (f.confirmed_at === null || f.confirmed_at === undefined) &&
                (f.cancelled_at === null || f.cancelled_at === undefined),
              _ => '送信予約済み',
            )
            .otherwise<followStatus>(_ => '不明'),
          pharmacist: this.pharmacists.find(ph => ph.id === follow.pharmacist_id)
        };
      });
    });
  }

  async pageEvent(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    try {
      this.loading = true;
      this.followsForDisplay = await this.fetchFollows({
        status: this.statusFilterString,
        last_key: this.pageNumber > 0 ? this.lastKeys[this.pageNumber - 1] : undefined,
      });
      this.filterByName();
    } finally {
      this.loading = false;
    }
  }

  async filterByStatus() {
    this.statusFilter = await this.popupCheckboxService.showOverlay(
      this.stateFilterControllerOrigin.elementRef,
      ['送信予約済み', '回答待ち（未読）', '回答待ち（既読）', '未確認', '確認済み', 'キャンセル済み'],
      this.statusFilter,
    );
    try {
      this.loading = true;
      this.pageNumber = 0;
      this.lastKeys = [];
      console.log('params', this.statusFilterString);
      this.followsForDisplay = await this.fetchFollows({ status: this.statusFilterString });
    } finally {
      this.loading = false;
    }
  }

  private filterByName() {
    const filters = (this.patientFilterControl.value as string).trim().split(' ');
    this.followsDisabled = this.followsForDisplay.map(
      follow =>
        !(
          (filters.length === 1 && !filters[0]) ||
          filters.every(f => this.getPatientName(follow.patient_info).includes(f)) ||
          filters.every(f => this.getPatientNameKana(follow.patient_info).includes(f))
        ),
    );
  }

  openDialog(row: PCFForDisplay) {
    const dialogRef = this.dialog.open(FollowDetailDialog, { data: row, minWidth: 450 });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result?.needsFetch) {
        this.followsForDisplay = await this.fetchFollows({ status: this.statusFilterString });
      }
    });
  }

  async reloadEvent() {
    this.loading = true;
    this.pageNumber = 0;
    this.lastKeys = [];
    try {
      this.followsForDisplay = await this.fetchFollows({ status: this.statusFilterString });
      this.filterByName();
    } finally {
      this.loading = false;
    }
  }

  getPatientName(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name ?? '') + (patientInfo?.given_name ?? '');
  }

  getPatientNameKana(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name_kana ?? '') + (patientInfo?.given_name_kana ?? '');
  }

  getPharmacistName(pharmacist?: IPharmacist) {
    return (pharmacist?.family_name ?? '') + (pharmacist?.given_name ?? '');
  }
}


@Component({
  selector: 'follow-detail-dialog',
  templateUrl: './follow-detail-dialog.html',
  styleUrls: ['./follow-list.component.scss'],
})
export class FollowDetailDialog {
  loading = false;
  constructor(
    private pcfService: PcfService,
    public dialogRef: MatDialogRef<FollowDetailDialog>,
    @Inject(MAT_DIALOG_DATA) public readonly follow: PCFForDisplay,
  ) { }

  async confirm() {
    if (!confirm('確認済みに変更します。よろしいですか？')) {
      return;
    }
    try {
      this.loading = true;
      await this.pcfService.confirm(this.follow.id);
      this.dialogRef.close({ needsFetch: true });
    } finally {
      this.loading = false;
    }
  }

  async cancel() {
    if (!confirm('回答待ちの服薬フォローを取り消します。よろしいですか？')) {
      return;
    }
    try {
      this.loading = true;
      await this.pcfService.cancel(this.follow.id);
      this.dialogRef.close({ needsFetch: true });
    } finally {
      this.loading = false;
    }
  }

  async delete() {
    confirm('服薬フォローを削除します。よろしいですか？');
    try {
      this.loading = true;
      await this.pcfService.remove(this.follow.id);
      this.dialogRef.close({ needsFetch: true });
    } finally {
      this.loading = false;
    }
  }

  followAnswer(i: number): string[] | string {
    return this.follow.pcf_answers?.[i].answers ?? '(無回答)';
  }

  getPatientName(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name ?? '') + (patientInfo?.given_name ?? '');
  }

  getPatientNameKana(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name_kana ?? '') + (patientInfo?.given_name_kana ?? '');
  }
}

interface PCFForDisplay extends IPCF {
  status: followStatus;
  pharmacist?: IPharmacist;
}
