import { Component, OnInit } from '@angular/core';
import { PCFTemplateService } from 'src/services/api/pcf-template.service';
import { IPatientInfo, IPCFTemplate, IPharmacist } from 'src/models';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { when } from 'src/app/modules/when';
import { IPCF, Pcf } from 'src/models/pcf';
import { PcfService } from 'src/services/api/pcf.service';
import { IQAT, QuestionType } from 'src/models/qa-template';
import { DatePipe } from '@angular/common';
import { PharmacistService } from 'src/services/api/pharmacist.service';

type followStatus = '不明' | '確認済み' | '未確認' | '回答待ち（未読）' | '回答待ち（既読）' | '送信予約済み' | 'キャンセル済み';

@Component({
  selector: 'app-pcf-templates',
  templateUrl: './pcf-templates.component.html',
  styleUrls: ['./pcf-templates.component.scss'],
})
export class PcfTemplatesComponent implements OnInit {
  // pcfs: PCFForDisplay[] = [];
  followsForDisplay: PCFForDisplay[] = [];

  newTemplates: any[] = [
    'new1',
    'new2'
  ];

  status = ['unsent', 'sent', 'read', 'answered', 'confirmed', 'cancelled'];
  statusFilter = [true, true, true, true, true, false];

  private get statusFilterString() {
    return this.statusFilter
      .reduce((acc: string, cur, index) => (cur ? acc + this.status[index] + ',' : acc), '')
      .slice(0, -1);
  }

  constructor(
    private pcfTemplateService: PCFTemplateService,
    private readonly pharmacistsService: PharmacistService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private pcfService: PcfService,
    private datePipe: DatePipe,
  ) {}

  pcfTemplates: any[] = [];
  templateGroups: { [x: string]: any[] } = { その他のテンプレート: [] };
  tempTemplate: any
  private pharmacists: IPharmacist[] = [];

  get groupNames() {
    return Object.keys(this.templateGroups).sort((a, b) =>
      when([a, b])
        .on(
          v => v[0] === 'その他のテンプレート',
          _ => 1,
        )
        .on(
          v => v[1] === 'その他のテンプレート',
          _ => -1,
        )
        .otherwise(_ => (a < b ? -1 : 1)),
    );
  }

  historyName = 'pcf_history'

  loading = true;

  async ngOnInit() {
    this.loading = true;
    try {
      await this.fetchPcfTemplates();
      this.pharmacists = await this.fetchPharmacists();
      this.followsForDisplay = await this.fetchFollows({ status: this.statusFilterString });

      // this.pcfs = (await this.pcfService.findAll())
      //   .map(pcf => new Pcf(pcf))
      //   .filter(pcf => pcf.send_at !== undefined)
      //   // filterでpci.send_atがundefinedにならないことを確認済み
      //   // tslint:disable-next-line
      //   .sort((a, b) => b.send_at! - a.send_at!);

    } finally {
      this.loading = false;
    }
  }

  private fetchPharmacists() {
    return this.pharmacistsService.findAll();
  }

  private async fetchPcfTemplates() {
    this.templateGroups = { その他のテンプレート: [] };
    this.pcfTemplateService.findAll().then(result =>
      result.forEach(t => {
        if (t.name?.includes('\\')) {
          const folder = t.name?.split('\\', 2)[0];
          t.name = t.name?.split('\\', 2)[1];
          if (this.templateGroups[folder] !== undefined) {
            this.templateGroups[folder].push(t);
          } else {
            this.templateGroups[folder] = [t];
          }
        } else {
          this.templateGroups.その他のテンプレート.push(t);
        }
      }),
    );
  }

  async fetchFollows(params?: { status?: string; last_key?: string }): Promise<PCFForDisplay[]> {
    return this.pcfService.findAllWithPagination({ ...params }).then(result => {
      // this.followsForDisplay = result.pcfs.map(follow => {
      return  result.pcfs.map(follow => {
        return {
          ...follow,
          status: when(follow)
            .on<followStatus>(
              f => f.confirmed_at !== null && f.confirmed_at !== undefined,
              _ => '確認済み',
            )
            .on<followStatus>(
              f => f.cancelled_at !== null && f.cancelled_at !== undefined,
              _ => 'キャンセル済み',
            )
            .on<followStatus>(
              f =>
                f.answered_at !== null &&
                f.answered_at !== undefined &&
                (f.confirmed_at === null || f.confirmed_at === undefined),
              _ => '未確認',
            )
            .on<followStatus>(
              f =>
                f.sent_at !== null &&
                f.sent_at !== undefined &&
                (f.first_read_at === null || f.first_read_at === undefined) &&
                (f.answered_at === null || f.answered_at === undefined) &&
                (f.confirmed_at === null || f.confirmed_at === undefined) &&
                (f.cancelled_at === null || f.cancelled_at === undefined),
              _ => '回答待ち（未読）',
            )
            .on<followStatus>(
              f =>
                f.sent_at !== null &&
                f.sent_at !== undefined &&
                f.first_read_at !== null && f.first_read_at !== undefined &&
                (f.answered_at === null || f.answered_at === undefined) &&
                (f.confirmed_at === null || f.confirmed_at === undefined) &&
                (f.cancelled_at === null || f.cancelled_at === undefined),
              _ => '回答待ち（既読）',
            )
            .on<followStatus>(
              f =>
                f.send_at !== null &&
                f.send_at !== undefined &&
                (f.sent_at === null || f.sent_at === undefined) &&
                (f.answered_at === null || f.answered_at === undefined) &&
                (f.confirmed_at === null || f.confirmed_at === undefined) &&
                (f.cancelled_at === null || f.cancelled_at === undefined),
              _ => '送信予約済み',
            )
            .otherwise<followStatus>(_ => '不明'),
          pharmacist: this.pharmacists.find(ph => ph.id === follow.pharmacist_id)
        };
      });
      // this.totalRecords = result.pagination.totalrecords;
      // if (result.pagination.last_key && this.pageNumber >= this.lastKeys.length) {
      //   this.lastKeys.push(result.pagination.last_key);
      // }
    });
  }


  async reloadEvent() {
    this.loading = true;
    try {
      await this.fetchPcfTemplates();
    } finally {
      this.loading = false;
    }
  }

  async deleteFolder(folderName: string) {
    if (
      !confirm(
        `グループ ${folderName} を削除しますか？\n( グループ内のテンプレートは 「その他のテンプレート」 に移動されます )`,
      )
    ) {
      return;
    }
    await Promise.all(
      this.templateGroups[folderName].map(t => this.pcfTemplateService.update({ id: t.id, name: t.name })),
    )
      .then(_ => {
        this.templateGroups.その他のテンプレート.push(...this.templateGroups[folderName]);
        delete this.templateGroups[folderName];
      })
      .catch(error => {
        alert('グループを削除できませんでした');
        console.log(error);
      });
  }

  async dropEvent(event: CdkDragDrop<any>) {
    console.log('dropEvent() is called')

    if (event.previousContainer !== event.container) {
      if(event.previousContainer.data === this.historyName ){
        const pcf: PCFForDisplay = this.followsForDisplay[event.previousIndex]
        const templateName = this.datePipe.transform(pcf.send_at, 'yyyy/MM/dd HH:mm');
        const groupName = event.container.data.toString()
        this.tempTemplate = await this.createTemplateFromHistory(groupName, templateName!, pcf)
        let newTemplate: IPCFTemplate = {id: this.tempTemplate.data.id, pharmacy_id: pcf.pharmacy_id, name: templateName!, qa: pcf.pcf_questions}
        console.log("QA from history is dropped.")
        const tempArray = [newTemplate]
        transferArrayItem(
          tempArray,
          this.templateGroups[event.container.data],
          event.previousIndex,
          event.currentIndex,
        );  
      } else {
        transferArrayItem(
          this.templateGroups[event.previousContainer.data],
          this.templateGroups[event.container.data],
          event.previousIndex,
          event.currentIndex,
        );  
      }

      await this.pcfTemplateService
        .update({
          id: event.item.data.id !== undefined ? event.item.data.id : this.tempTemplate.id,
          name:
            (event.container.data.toString() !== 'その他のテンプレート' ? event.container.data.toString() + '\\' : '') +
            event.item.data.name,
        })
        .catch(error => {
          transferArrayItem(
            this.templateGroups[event.container.data],
            this.templateGroups[event.previousContainer.data],
            event.currentIndex,
            event.previousIndex,
          );
          console.log(error);
        });
    }
  }


  async createFolder() {
    const result = await this.dialog
      .open(PcfTemplateGroupNameDialog, { minWidth: '400px', maxWidth: '400px' })
      .afterClosed()
      .toPromise()
      .then(v => (v ? v.toString().trim() : ''));
    if (result && !this.templateGroups[result]) {
      this.templateGroups[result] = [];
    }
  }

  createTemplate(groupName: string) {
    this.router.navigate(
      ['/pharmacy/pcf-templates/new-template'],
      groupName !== 'その他のテンプレート' ? { queryParams: { groupName } } : undefined,
    );
  }

  async createTemplateFromHistory(groupName: string, templateName: string, pcf: PCFForDisplay):Promise<any> {
    const createdTemplate = this.pcfTemplateService.create({
      pharmacy_id: pcf.pharmacy_id,
      name: groupName + '\\' + templateName,
      qa: this.formatQuestions(pcf.pcf_questions!),
    });
    return createdTemplate
    // this.pcfTemplateService.update({ id: createdTemplate.id, name: groupName })

    // this.router.navigate(
    //   ['/pharmacy/pcf-templates/new-template-history'],
    //   groupName !== 'その他のテンプレート' ? { queryParams: { groupName } } : undefined,
    // );
  }

  private formatQuestions(questions: IQAT[]): IQAT[] {
    return questions.map(q =>
      q.type === QuestionType.checkbox || q.type === QuestionType.radio
        ? { type: q.type, question: q.question, answers: q.answers, required: q.required }
        : { type: q.type, question: q.question, answers: [], required: q.required },
    );
  }

  getPatientName(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name ?? '') + (patientInfo?.given_name ?? '');
  }

  getPatientNameKana(patientInfo?: IPatientInfo) {
    return (patientInfo?.family_name_kana ?? '') + (patientInfo?.given_name_kana ?? '');
  }

  getPharmacistName(pharmacist?: IPharmacist) {
    return (pharmacist?.family_name ?? '') + (pharmacist?.given_name ?? '');
  }

}

@Component({
  selector: 'app-pcf-template-group-name-dialog',
  templateUrl: 'pcf-template-group-name-dialog.html',
  styles: ['mat-dialog-content {font-size: 1.2rem}'],
})
export class PcfTemplateGroupNameDialog {
  readonly nameFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(new RegExp('^(?!.*\\\\).*')),
  ]);
  constructor(private readonly dialogRef: MatDialogRef<PcfTemplateGroupNameDialog>) {}
  confirm() {
    this.dialogRef.close(this.nameFormControl.value as string);
  }
}

interface PCFForDisplay extends IPCF {
  pharmacist?: IPharmacist;
}
