<ng-template #content><ng-content></ng-content></ng-template>

<mat-form-field fxFlex *ngIf="formControl !== null">
  <mat-label [attr.for]="name"><ng-container *ngTemplateOutlet="content"></ng-container></mat-label>
  <input
    matInput
    [name]="name"
    [(ngModel)]="model"
    [type]="type"
    [required]="isRequired"
    (ngModelChange)="changeValue()"
    [formControl]="formControl"
  />

  <mat-error *ngFor="let result of filteredValidations">{{ result.message }}</mat-error>
</mat-form-field>

<mat-form-field fxFlex *ngIf="formControl === null">
  <mat-label [attr.for]="name"><ng-container *ngTemplateOutlet="content"></ng-container></mat-label>
  <input
    matInput
    [name]="name"
    [(ngModel)]="model"
    [type]="type"
    [required]="isRequired"
    (ngModelChange)="changeValue()"
  />
  <mat-error>入力必須の項目です</mat-error>
</mat-form-field>
