<div class="root">
  <div fxLayout="column" fxLayoutGap="20px">
    <div
      *ngIf="!isActive"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="25px"
      class="active-warn-box inactive"
    >
      <div fxLayout="column">
        <div>この薬局は公開されていません。</div>
        <div class="no-mq-template-message" *ngIf="noMqTemplate">
          問診票テンプレートが登録されていないため、薬局を公開することができません。
        </div>
      </div>
      <loading-button
        color="note"
        [name]="'薬局を公開する'"
        [loading]="loading"
        [disabled]="!areAllFormsValid || noMqTemplate"
        (click)="activatePharmacy()"
        icon="publish"
      ></loading-button>
    </div>
    <div
      *ngIf="isActive"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="25px"
      class="active-warn-box active"
    >
      <div fxLayout="column">
        <div>この薬局は公開されています。</div>
      </div>
      <loading-button
        color="warn"
        [name]="'薬局を無効化する'"
        [loading]="loading"
        (click)="deactivatePharmacy()"
        icon="close"
      ></loading-button>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field class="form-field">
        <mat-label>薬局コード</mat-label>
        <input matInput type="text" [value]="pharmacyCode" readonly />
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>会社名</mat-label>
        <input matInput type="text" [formControl]="companyNameFormControl" />
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>店舗名</mat-label>
        <input matInput type="text" [formControl]="storeNameFormControl" required />
        <mat-error *ngIf="storeNameFormControl.hasError('required')">店舗名は<strong>必須</strong>です</mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>店舗名ふりがな</mat-label>
        <input matInput type="text" [formControl]="storeNameKanaFormControl" required />
        <mat-error *ngIf="storeNameKanaFormControl.hasError('required')"
          >店舗名ふりがなは<strong>必須</strong>です</mat-error
        >
        <mat-error
          *ngIf="!storeNameKanaFormControl.hasError('required') && storeNameKanaFormControl.hasError('pattern')"
        >
          <strong>ひらがな</strong>で入力してください
        </mat-error>
      </mat-form-field>
      <div fxLayout="row" fxLayoutAlign="space-between" class="zip-code-wrap">
        <mat-form-field class="form-field small">
          <mat-label>郵便番号 (ハイフン不要)</mat-label>
          <input matInput type="text" [formControl]="zipFormControl" required />
          <mat-error *ngIf="zipFormControl.hasError('required')">郵便番号は<strong>必須</strong>です</mat-error>
          <mat-error *ngIf="zipFormControl.hasError('pattern') && !zipFormControl.hasError('required')">
            郵便番号の形式が正しくありません
          </mat-error>
        </mat-form-field>
        <button
          fxFlexAlign="center"
          mat-stroked-button
          [disabled]="zipFormControl.hasError('pattern') || zipFormControl.hasError('required') || fetchingAddress"
          (click)="getAddress()"
        >
          住所検索
        </button>
      </div>

      <mat-form-field class="form-field">
        <mat-label>都道府県</mat-label>
        <input matInput type="text" [formControl]="prefectureFormControl" [matAutocomplete]="autoPrefecture" required />
        <mat-autocomplete #autoPrefecture="matAutocomplete">
          <mat-option *ngFor="let prefecture of filteredPrefectures | async" [value]="prefecture">
            {{ prefecture }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="prefectureFormControl.hasError('required')">都道府県は<strong>必須</strong>です</mat-error>
        <mat-error *ngIf="prefectureFormControl.hasError('pattern') && !prefectureFormControl.hasError('required')">
          都道府県を入力してください
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>住所1</mat-label>
        <input matInput type="text" [formControl]="address1FormControl" required />
        <mat-error *ngIf="address1FormControl.hasError('required')">住所1は<strong>必須</strong>です</mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>住所2</mat-label>
        <input matInput type="text" [formControl]="address2FormControl" required />
        <mat-error *ngIf="address2FormControl.hasError('required')">住所2は<strong>必須</strong>です</mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>住所3</mat-label>
        <input matInput type="text" [formControl]="address3FormControl" />
      </mat-form-field>

      <mat-form-field class="form-field">
        <mat-label>電話番号 (ハイフン不要)</mat-label>
        <input matInput type="tel" [formControl]="telFormControl" required />
        <mat-error *ngIf="telFormControl.hasError('required')">電話番号は<strong>必須</strong>です</mat-error>
        <mat-error *ngIf="telFormControl.hasError('pattern') && !telFormControl.hasError('required')">
          電話番号の形式が正しくありません
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>FAX番号 (ハイフン不要)</mat-label>
        <input matInput type="tel" [formControl]="faxFormControl" required />
        <mat-error *ngIf="faxFormControl.hasError('required')">FAX番号は<strong>必須</strong>です</mat-error>
        <mat-error *ngIf="faxFormControl.hasError('pattern') && !faxFormControl.hasError('required')">
          FAX番号の形式が正しくありません
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>Eメールアドレス</mat-label>
        <input matInput type="email" [formControl]="emailFormControl" required />
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          メールアドレスの形式が正しくありません
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">メールアドレスは<strong>必須</strong>です</mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>ホームページアドレス</mat-label>
        <input matInput type="text" [formControl]="webFormControl" />
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>営業時間</mat-label>
        <textarea matInput type="text" [(ngModel)]="businessHours"></textarea>
      </mat-form-field>
      <div class="form-field">
        <div>駐車場の有無<span class="required-mark"> *</span></div>
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-radio-group fxFlex fxLayoutGap="20px" type="unitTime" [(ngModel)]="isParkingAvailable" required>
            <mat-radio-button fxFlex [value]="true">有</mat-radio-button>
            <mat-radio-button fxFlex [value]="false">無</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div>
        <div fxLayout="row" fxLayoutAlign="space-between" class="zip-code-wrap">
          <div>位置情報<span class="required-mark"> *</span></div>
          <button fxFlexAlign="center" mat-stroked-button [disabled]="fetchingGeoLocation" (click)="getGeoLocation()">
            住所から自動入力
          </button>
        </div>
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field class="form-field small">
            <mat-label>緯度</mat-label>
            <input matInput type="text" [formControl]="latitudeFormControl" />
          </mat-form-field>
          <mat-form-field class="form-field small">
            <mat-label>経度</mat-label>
            <input matInput type="text" [formControl]="longitudeFormControl" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-field">
        <div>予約可能枠の単位時間<span class="required-mark"> *</span></div>
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-radio-group fxFlex fxLayoutGap="20px" type="unitTime" [formControl]="unitTimeFormControl" required>
            <mat-radio-button fxFlex [value]="15">15分</mat-radio-button>
            <mat-radio-button fxFlex [value]="30">30分</mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="unitTimeFormControl.hasError('required')"
            >単位時間の設定は<strong>必須</strong>です</mat-error
          >
        </div>
      </div>
      <div class="pharmacy-logo" fxLayout="column" fxLayoutGap="10px">
        <div>薬局ロゴ画像</div>
        <div fxFlexAlign="center" class="upload-area logo-upload-area">
          <div *ngIf="logoImage" class="uploaded-pictures-display" fxLayout="row" fxLayoutAlign="center">
            <div class="uploaded-picture-wrap">
              <img [src]="logoImage.source" />
              <div class="clear-image-button" fxLayout="row" fxLayoutAlign="center" (click)="removeLogoImage()">
                <mat-icon fxFlexAlign="center">close</mat-icon>
              </div>
            </div>
          </div>
          <div class="upload-area-appearance" fxLayout="column" fxLayoutAlign="center">
            <div fxLayout="column" fxFlexAlign="center">
              <mat-icon fxFlexAlign="center">image</mat-icon>
              <div class="upload-instruction" fxLayout="column" fxLayoutAlign="center center">
                <div>ここをクリック</div>
                <div>または</div>
                <div>画像をドロップ</div>
              </div>
            </div>
          </div>
          <input type="file" class="file-input" [disabled]="loading" (input)="dropLogoImage($event)" />
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <div>店舗外観写真</div>
        <div class="upload-area">
          <div *ngIf="exteriorImages.length" class="uploaded-pictures-display" fxLayout="row wrap" fxLayoutGap="10px">
            <div *ngFor="let image of exteriorImages; index as i" class="uploaded-picture-wrap">
              <img [src]="image.source" />
              <div class="clear-image-button" fxLayout="row" fxLayoutAlign="center" (click)="removeExteriorImage(i)">
                <mat-icon fxFlexAlign="center">close</mat-icon>
              </div>
            </div>
          </div>
          <div class="upload-area-appearance" fxLayout="column" fxLayoutAlign="center">
            <div fxLayout="column" fxFlexAlign="center">
              <mat-icon fxFlexAlign="center">image</mat-icon>
              <div class="upload-instruction" fxLayout="column" fxLayoutAlign="center center">
                <div>ここをクリック</div>
                <div>または</div>
                <div>画像をドロップ</div>
              </div>
            </div>
          </div>
          <input type="file" class="file-input" [disabled]="loading" (input)="dropExteriorImage($event)" />
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <div>店舗内写真</div>
        <div class="upload-area">
          <div *ngIf="interiorImages.length" class="uploaded-pictures-display" fxLayout="row wrap" fxLayoutGap="10px">
            <div *ngFor="let image of interiorImages; index as i" class="uploaded-picture-wrap">
              <img [src]="image.source" />
              <div class="clear-image-button" fxLayout="row" fxLayoutAlign="center" (click)="removeInteriorImage(i)">
                <mat-icon fxFlexAlign="center">close</mat-icon>
              </div>
            </div>
          </div>
          <div class="upload-area-appearance" fxLayout="column" fxLayoutAlign="center">
            <div fxLayout="column" fxFlexAlign="center">
              <mat-icon fxFlexAlign="center">image</mat-icon>
              <div class="upload-instruction" fxLayout="column" fxLayoutAlign="center center">
                <div>ここをクリック</div>
                <div>または</div>
                <div>画像をドロップ</div>
              </div>
            </div>
          </div>
          <input type="file" class="file-input" [disabled]="loading" (input)="dropInteriorImage($event)" />
        </div>
      </div>
      <mat-form-field class="form-field">
        <mat-label>フリーコメント</mat-label>
        <textarea matInput type="text" [(ngModel)]="freeComment"></textarea>
      </mat-form-field>
      <div>
        <loading-button
          [name]="'変更を保存'"
          color="primary"
          (click)="save()"
          [disabled]="!areAllFormsValid"
          icon="save"
        ></loading-button>
      </div>
    </div>
  </div>
</div>
