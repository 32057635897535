<button
  mat-raised-button
  [color]="color"
  [class.spinner]="loading"
  [disabled]="loading || disabled"
  [style.pointer-events]="loading || disabled ? 'none' : 'auto'"
>
  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="5px">
    <mat-icon fxFlexAlign="center" *ngIf="icon">
      {{ icon }}
    </mat-icon>
    <div fxFlexAlign="center">{{ name }}</div>
  </div>
</button>
