import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { InstructionClass } from 'src/models/pci';
@Pipe({ name: 'reservation_class' })
export class ReservationClassPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === InstructionClass.pci,
        _ => '服薬指導',
      )
      .on(
        v => v === InstructionClass.pcf,
        _ => '服薬フォロー',
      )
      .on(
        v => v === InstructionClass.pcf,
        _ => 'チャットボット服薬フォロー',
      )
      .otherwise(_ => '不明');
  }
}
