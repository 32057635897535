import { Injectable } from '@angular/core';
import { ServiceBase } from '../service-base';
import { CognitoService } from '../cognito.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class HealthInsuranceCardService extends ServiceBase<any> {
  propertyName = 'healthinsurancecards';

  constructor(cognito: CognitoService, store: Store) {
    super(cognito, store, 'health_insurance_cards');
  }
}
