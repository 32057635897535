import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cert-error',
  templateUrl: './cert-error.component.html',
  styleUrls: ['./cert-error.component.scss'],
})
export class CertErrorComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
