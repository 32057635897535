import { IEntityBase } from './entity-base';

export interface IQATemplate extends IEntityBase {
  id: string;
  pharmacy_id: string;
  name?: string;
  qa?: IQAT[];
}

export interface IQAT {
  type: QuestionType;
  question: string;
  answers: string[];
  required: boolean;
}

export enum QuestionType {
  radio = 'radio',
  textbox = 'textbox',
  checkbox = 'checkbox',
}
