<div class="out">
  <div class="root" fxLayout="column" fxLayoutGap="15px">
    <div *ngIf="title" class="title">{{ title }}</div>
    <div>{{ message }}</div>
    <div class="input-wrap" fxFlexAlign="stretch" fxLayout="column">
      <input class="input" type="password" [formControl]="passwordFormControl" #input />
    </div>
    <div *ngIf="constraints.length">
      <div>以下を満たすパスワードを設定してください。</div>
      <ul>
        <li *ngFor="let constraint of constraints">{{ constraint }}</li>
      </ul>
    </div>
    <div fxLayout="row-reverse" fxLayoutGap="15px">
      <button class="button cancel-button" (click)="cancel()">キャンセル</button>
      <button
        class="button ok-button"
        (click)="submit()"
        [class.disabled]="passwordFormControl.hasError('pattern') || passwordFormControl.hasError('required')"
        [disabled]="passwordFormControl.hasError('pattern') || passwordFormControl.hasError('required')"
      >
        OK
      </button>
    </div>
  </div>
</div>
