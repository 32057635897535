import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';

@Pipe({ name: 'settlement_method' })
export class SettlementMethodPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === 'credit_card',
        _ => 'クレジット',
      )
      .otherwise(_ => 'その他');
  }
}
