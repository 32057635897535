import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { InstructionStatus } from 'src/models/pci';

@Pipe({ name: 'reservation_status' })
export class ReservationStatusPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === InstructionStatus.requested,
        _ => '未確認',
      )
      .on(
        v => v === InstructionStatus.confirmed,
        _ => '確定',
      )
      .on(
        v => v === InstructionStatus.completed,
        _ => '完了',
      )
      .on(
        v => v === InstructionStatus.incomplete,
        _ => '実施不可',
      )
      .on(
        v => v === InstructionStatus.withdrawn,
        _ => '取り下げ',
      )
      .on(
        v => v === InstructionStatus.cancelled_by_patient,
        _ => '患者のキャンセル',
      )
      .on(
        v => v === InstructionStatus.cancelled_by_pharmacist,
        _ => '薬剤師のキャンセル',
      )
      .on(
        v => v === InstructionStatus.declined,
        _ => '予約お断り',
      )
      .on(
        v => v === InstructionStatus.meeting_performed,
        _ => '実施中',
      )
      .on(
        v => v === InstructionStatus.meeting_completed,
        _ => '実施済',
      )
      .otherwise(_ => '不明');
  }
}
