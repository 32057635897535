import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { CognitoService } from './cognito.service';

@Injectable({
  providedIn: 'root',
})
export class ZipToAddressService {
  constructor(private readonly cognito: CognitoService) { }
  async getAddress(zipCode: string) {
    return axios
      .get(environment.api_base_url + `pharmacy/get_address?postal_code=${zipCode}`, {
        headers: {
          Authorization: await this.cognito.getAccessToken().then(accessToken => accessToken.getJwtToken()),
          'Content-Type': 'application/json',
        },
      })
      .then(response =>
        response.data.results
          ? {
            notFound: false,
            zipCode: (response.data.results[0].zipcode ?? '') as string,
            prefecture: (response.data.results[0].address1 ?? '') as string,
            prefectureCode: response.data.results[0].prefcode as string,
            // prettier-ignore
            address: (response.data.results[0].address2 ?? '') as string,
            address2: (response.data.results[0].address3 ?? '') as string,
            prefectureKana: (response.data.results[0].kana1 ?? '') as string,
            addressKana: (response.data.results[0].kana2 ?? '') as string,
            addressKana2: (response.data.results[0].kana3 ?? '') as string,
          }
          : {
            notFound: true,
            zipCode: '',
            prefecture: '',
            prefectureCode: '',
            address: '',
            address2: '',
            prefectureKana: '',
            addressKana: '',
            addressKana2: '',
          },
      )
      .catch(error => {
        throw error;
      });
  }
}
