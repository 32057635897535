<h1 mat-dialog-title>メッセージの編集</h1>
<div mat-dialog-content fxLayout="column">
  <mat-form-field>
    <mat-label>患者へのメッセージを入力</mat-label>
    <textarea matInput [(ngModel)]="message"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">
  <button mat-button mat-dialog-close>閉じる</button>
  <button mat-button color="primary" (click)="save()">保存</button>
</div>
