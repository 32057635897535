import { Component, Inject, InjectionToken, Injector, Input, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSession from '../app-store/reducers';
import { logoutSessions } from 'src/app/app-store/actions/session.actions';
import { IPharmacist, IPharmacy } from 'src/models';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getLastChild } from '../modules/routeModule';
import { getLoginSession } from '../modules/storeModules';
import { filter, map } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { NewArrival, NewArrivalService, NewArrivalType } from 'src/services/new-arrival.service';
import { CdkOverlayOrigin, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { animate, style, transition, trigger } from '@angular/animations';
import { Auth, Hub } from 'aws-amplify';
import { when } from '../modules/when';
import { Pcf } from 'src/models/pcf';

const NOTIFICATION_DATA = new InjectionToken<{ overlayRef: OverlayRef }>('NOTIFICATION_DATA');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  pharmacist$: Observable<IPharmacist | null> = of(null);
  pharmacy$: Observable<IPharmacy | null> = of(null);
  urlSubscription: Subscription;
  url$: Observable<string> = of('');
  parentPass$: Observable<string | null> = of(null);
  currentPath$: Observable<string | null> = of(null);
  showLogoutButton$ = of(false);
  linkToTop$: Observable<string> = of('/index');
  noticesSubscription: Subscription;
  notices: NewArrival[] = [];
  previousNotices: NewArrival[] = [];
  expiration?: NodeJS.Timeout;
  @Input() title!: string;
  @ViewChild('overlayOrigin') overlayOrigin!: CdkOverlayOrigin;
  stackUrl: string[] = [];

  constructor(
    private readonly store: Store<fromSession.State>,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly newArrivalService: NewArrivalService,
    private readonly overlay: Overlay,
    private readonly _injector: Injector,
  ) {
    const session$ = getLoginSession(this.store);
    this.pharmacist$ = session$.pipe(map(s => s.pharmacist));
    this.pharmacy$ = session$.pipe(map(s => (s.pharmacy ? s.pharmacy : s.pharmacist?.pharmacy ?? null)));
    this.url$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => (e as NavigationEnd).url),
    );
    this.parentPass$ = this.url$.pipe(map(_ => getLastChild(this.activatedRoute.snapshot).data.backPass ?? null));
    this.currentPath$ = this.url$.pipe(
      map(_ => {
        const snapshot = getLastChild(this.activatedRoute.snapshot);
        return snapshot.data?.backPass ? snapshot.data?.backPass : snapshot.routeConfig?.path ?? null;
      }),
    );
    this.showLogoutButton$ = this.url$.pipe(map(u => u !== '/' && u !== '/index' && !u.includes('login')));
    this.linkToTop$ = session$.pipe(
      map(s => (s.pharmacist ? '/pharmacist/index' : s.pharmacy ? '/pharmacy/index' : '/index')),
    );
    this.noticesSubscription = this.newArrivalService.newArrivals.subscribe(n => {
      this.previousNotices = this.notices;
      this.notices = n;
      const difference = this.notices.reduce(
        (acc: { prescription: number; pci: number; pcf: number }, cur) => {
          if (
            !this.previousNotices.find(
              p => cur.patientName === p.patientName && cur.date === p.date && cur.type === p.type,
            )
          ) {
            acc[cur.type] += 1;
            return acc;
          }
          return acc;
        },
        { prescription: 0, pci: 0, pcf: 0 },
      );
      const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
      if (isSupported() && !document.hasFocus() && (difference.prescription || difference.pci)) {
        new Notification('Connect Online', {
          icon: '/assets/logo_sq.png',
          body:
            (difference.pci ? `服薬指導に関する新しい通知が${difference.pci}件あります。\n` : '') +
            (difference.prescription ? `処方箋に関する新しい通知が${difference.prescription}件あります。\n` : '') +
            (difference.pcf ? `服薬フォローに関する新しい通知が${difference.pcf}件あります。` : ''),
        }).onclick = _ => {
          window.focus();
        };
      }
    });
    this.urlSubscription = this.url$.subscribe(async _ => {
      const [cognitoUser, currentSession] = await Promise.all([Auth.currentAuthenticatedUser(), Auth.currentSession()]);
      cognitoUser.refreshSession(currentSession.getRefreshToken(), (error: Error) => {
        if (error) {
          console.log(error);
        }
      });
    });

    Hub.listen('auth', async data => {
      if (data.payload.event === 'tokenRefresh') {
        if (this.expiration) {
          clearTimeout(this.expiration);
        }
        const willBeExpiredAt = await Auth.currentSession().then(s => s.getAccessToken().getExpiration());
        this.expiration = setTimeout(() => {
          this.logout();
        }, willBeExpiredAt * 1000 - new Date().getTime());
      }
    });

    this.url$.subscribe((e) => {
      this.stackUrl.push(e);
    });
  }

  ngOnDestroy() {
    this.noticesSubscription.unsubscribe();
    this.urlSubscription.unsubscribe();
  }

  async logout() {
    this.store.dispatch(logoutSessions());
  }

  back() {
    const routerSnapshot = getLastChild(this.activatedRoute.snapshot);
    if (routerSnapshot.data.title !== '服薬指導実施') {
      //this.router.navigate([`${routerSnapshot.data.backPass ?? null}`]);
      this.stackUrl.pop();
      const backurl = this.stackUrl.pop();
      if (backurl) {
        const backAry = backurl.split('?');
        if (backAry.length > 1) {
          const paramAry = backAry[1].split('&').reduce((target: any, val: string) => { const vary = val.split('='); target[vary[0]] = vary[1]; return target; }, {});
          this.router.navigate([backAry[0]], {
            queryParams: paramAry,
          });
          return;
        }
      }
      this.router.navigate([backurl ?? '/pharmacist/index']);
      return;
    }
    if (confirm('服薬指導実施画面から離れてもよろしいですか？')) {
      this.router.navigate([`pharmacist/reservation/${routerSnapshot.params.pciId}`]);
    }
  }

  async openNotificationsOverlay() {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.overlayOrigin.elementRef)
      .withPositions([{ originX: 'center', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]);
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const config = {
      positionStrategy,
      scrollStrategy,
      width: 'auto',
      height: 'auto',
      hasBackdrop: true,
      backdropClass: ['notification-overlay-backdrop'],
    };
    const overlayRef = this.overlay.create(config);
    const injector = Injector.create({
      parent: this._injector,
      providers: [
        {
          provide: NOTIFICATION_DATA,
          useValue: { ref: overlayRef },
        },
      ],
    });
    overlayRef.attach(new ComponentPortal(HeaderNotificationsOverlayComponent, null, injector));
    overlayRef.backdropClick().subscribe(_ => overlayRef.dispose());
  }
}

@Component({
  selector: 'header-notifications-overlay',
  templateUrl: 'header-notifications-overlay.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.2s 0s ease-in-out', style({ opacity: 1 }))]),
    ]),
  ],
})
export class HeaderNotificationsOverlayComponent {
  data$: Observable<NewArrival[]> = of([]);
  ref: OverlayRef;

  constructor(
    @Inject(NOTIFICATION_DATA)
    public readonly param: {
      ref: OverlayRef;
    },
    newArrivalService: NewArrivalService,
  ) {
    this.ref = param.ref;
    this.data$ = newArrivalService.newArrivals;
  }

  icon(type: NewArrivalType) {
    return when(type)
      .on(
        v => v === 'pci',
        _ => 'event_note',
      )
      .on(
        v => v === 'pcf',
        _ => 'question_answer',
      )
      .on(
        v => v === 'prescription',
        _ => 'receipt',
      )
      .otherwise(_ => '');
  }

  public sliceString(str: string, letters: number) {
    if (str.length < letters) {
      return str;
    }
    return str.slice(0, letters) + '...';
  }

  onclick() {
    this.ref.dispose();
  }
}
