import { when } from 'src/app/modules/when';
import { ICognitoAccount } from './cognito-account';
import { IEntityBase } from './entity-base';
import { IQAT } from './qa-template';

export interface IChatbot extends IEntityBase {
  id: string;
  pharmacy_id: string;
  pharmacist_id?: string;
  patient_account_id: string;
  patient_info_id: string;
  start_time?: string;
  end_time?: string;
  status: ChatbotStatus;
  video_chat_id?: string;
  template_id?: string;
  qa?: IQAT[] | null;
}

export enum ChatbotStatus {
  planned = 'planned',
  notified = 'notified',
  started = 'started',
  read = 'read',
  finished = 'finished',
  cancelled = 'cancelled',
}

export class Chatbot implements IChatbot {
  id: string;
  pharmacy_id: string;
  pharmacist_id?: string;
  patient_account_id: string;
  patient_info_id: string;
  start_time?: string;
  end_time?: string;
  status: ChatbotStatus;
  video_chat_id?: string;
  template_id?: string;
  qa?: IQAT[] | null;

  constructor(chatbot: IChatbot) {
    this.id = chatbot.id;
    this.pharmacy_id = chatbot.pharmacy_id;
    this.pharmacist_id = chatbot.pharmacist_id;
    this.patient_account_id = chatbot.patient_account_id;
    this.patient_info_id = chatbot.patient_info_id;
    this.start_time = chatbot.start_time;
    this.end_time = chatbot.end_time;
    this.status = chatbot.status;
    this.video_chat_id = chatbot.video_chat_id;
    this.template_id = chatbot.template_id;
    this.qa = chatbot.qa;
  }

  get isNotified(): boolean {
    return (
      this.status === ChatbotStatus.notified ||
      this.status === ChatbotStatus.read ||
      this.status === ChatbotStatus.finished
    );
  }

  get isRead(): boolean {
    return this.status === ChatbotStatus.read || this.status === ChatbotStatus.finished;
  }

  get isFinished(): boolean {
    return this.status === ChatbotStatus.finished;
  }

  get statusText(): string {
    return when(this.status)
      .on(
        v => v === ChatbotStatus.cancelled,
        _ => 'キャンセル',
      )
      .on(
        v => v === ChatbotStatus.finished,
        _ => '完了済み',
      )
      .on(
        v => v === ChatbotStatus.notified,
        _ => '通知済み',
      )
      .on(
        v => v === ChatbotStatus.planned,
        _ => '計画済み',
      )
      .on(
        v => v === ChatbotStatus.read,
        _ => '既読',
      )
      .on(
        v => v === ChatbotStatus.started,
        _ => '開始済み',
      )
      .otherwise(_ => '不明');
  }

  static compareByStartTime(a: Chatbot, b: Chatbot): number {
    return compareTime(a, b, 'start_time');
  }
  static compareByEndTime(a: Chatbot, b: Chatbot): number {
    return compareTime(a, b, 'end_time');
  }
}

const compareTime = (a: Chatbot, b: Chatbot, type: 'start_time' | 'end_time') =>
  when([a[type] ?? '', b[type] ?? ''])
    .on(
      v => v[0] > v[1],
      _ => -1,
    )
    .on(
      v => v[1] > v[2],
      _ => 1,
    )
    .otherwise(_ => 0);
