import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
if (isSupported() ? Notification.permission === 'default' : false) {
  Notification.requestPermission();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
