<form class="login_form" fxLayout="column" fxLayoutAlign="center">
  <div fxLayout="row" fxFlex fxLayoutAlign="space-between">
    <mat-form-field>
      <mat-label for="pharmacy_code">薬局コード</mat-label>
      <input type="text" matInput autocomplete="additional-name" name="pharmacy_code" [(ngModel)]="pharmacyCode" />
    </mat-form-field>
    <mat-form-field>
      <mat-label for="user_id">薬剤師ID</mat-label>
      <input type="text" matInput autocomplete="username" name="user_id" [(ngModel)]="userId" />
    </mat-form-field>
  </div>
  <mat-form-field fxFlex>
    <mat-label for="password">パスワード</mat-label>
    <input type="password" matInput autocomplete="current-password" name="password" [(ngModel)]="password" />
  </mat-form-field>
  <div class="button-area" fxFlex>
    <button class="round-button" mat-raised-button color="primary" (click)="login()">ログイン</button>
  </div>
</form>
