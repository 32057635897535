import { IPharmacy } from '.';
import { IPatientInfo } from './patient-info';
import { when } from 'src/app/modules/when';
import { IQAT } from './qa-template';

export interface IPCF {
  id: string;
  pharmacy_id: string;
  pharmacy?: IPharmacy;
  pharmacist_id: string;
  patient_account_id?: string;
  patient_info_id?: string;
  patient_info?: IPatientInfo;
  template_id?: string;
  pcf_questions?: IQAT[];
  pcf_answers?: IQAT[];
  send_at?: number;
  sent_at?: number;
  first_read_at?: number; // 患者がはじめてフォローを確認した日時
  last_read_at?: number; // 患者がフォローを確認した直近の日時
  confirmed_at?: number; // 薬剤師が患者の回答を確認した日時
  answered_at?: number; // 患者がフォローに回答した日時
  cancelled_at?: number;
  finished?: boolean; // フォロー終了フラグ(薬剤師が回答を確認後、終了を押すと真)
  created_at?: number;
  updated_at?: number;
  pharmacist_name?: string;
}

export enum PcfStatus {
  confirmed = 'confirmed',
  answered = 'answered',
  read = 'read',
  sent = 'sent',
  send = 'send',
  unknown = 'unknown',
}
export class Pcf {
  id: string;
  pharmacy_id: string;
  pharmacist_id: string;
  patient_account_id?: string;
  patient_info_id?: string;
  template_id?: string;
  pcf_questions?: IQAT[];
  pcf_answers?: IQAT[];
  send_at?: number;
  sent_at?: number;
  first_read_at?: number;
  last_read_at?: number;
  confirmed_at?: number;
  answered_at?: number;
  finished?: boolean;
  created_at?: number;
  updated_at?: number;
  status: PcfStatus;
  patient_info?: IPatientInfo;
  pharmacist_name?: string;

  constructor(pcf: IPCF) {
    this.id = pcf.id;
    this.pharmacy_id = pcf.pharmacy_id;
    this.pharmacist_id = pcf.pharmacist_id;
    this.patient_account_id = pcf.patient_account_id;
    this.patient_info_id = pcf.patient_info_id;
    this.template_id = pcf.template_id;
    this.pcf_questions = pcf.pcf_questions;
    this.pcf_answers = pcf.pcf_answers;
    this.send_at = pcf.send_at;
    this.sent_at = pcf.sent_at;
    this.first_read_at = pcf.first_read_at;
    this.last_read_at = pcf.last_read_at;
    this.confirmed_at = pcf.confirmed_at;
    this.answered_at = pcf.answered_at;
    this.finished = pcf.finished;
    this.created_at = pcf.created_at;
    this.updated_at = pcf.updated_at;
    this.patient_info = pcf.patient_info;
    this.pharmacist_name = pcf.pharmacist_name;
    this.status = when(pcf)
      .on<PcfStatus>(
        f => f.confirmed_at !== null && f.confirmed_at !== undefined,
        _ => PcfStatus.confirmed,
      )
      .on<PcfStatus>(
        f => f.answered_at !== null && f.answered_at !== undefined,
        _ => PcfStatus.answered,
      )
      .on<PcfStatus>(
        f => f.sent_at !== null && f.sent_at !== undefined,
        _ => PcfStatus.sent,
      )
      .on<PcfStatus>(
        f => f.send_at !== null && f.send_at !== undefined,
        _ => PcfStatus.send,
      )
      .otherwise<PcfStatus>(_ => PcfStatus.unknown);
  }
}
