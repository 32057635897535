import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Store } from '@ngrx/store';
import axios from 'axios';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { isNotNull } from 'src/app/modules/nonNullPredicate';
import { getLoginSession } from 'src/app/modules/storeModules';
import { environment } from 'src/environments/environment';
import { CognitoService } from './cognito.service';
import { IPharmacy } from 'src/models';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private readonly _messageReceive = new BehaviorSubject<null>(null);
  pharmacy$: Observable<IPharmacy | null> = of(null);
  private pharmacy: IPharmacy | null = null;

  constructor(
    private readonly messaging: AngularFireMessaging,
    private readonly cognito: CognitoService,
    private readonly store: Store,
  ) {
    const session$ = getLoginSession(this.store);
    this.pharmacy$ = session$.pipe(map(s => (s.pharmacy ? s.pharmacy : s.pharmacist?.pharmacy ?? null)));
    this.pharmacy$.subscribe((e) => {
      this.pharmacy = e;
    });
    setTimeout(async () => {
      this.messaging.useServiceWorker(await navigator.serviceWorker.register('firebase-messaging-sw.js'));
      combineLatest([
        getLoginSession(this.store).pipe(
          map(s => s.pharmacist),
          filter(isNotNull),
          distinctUntilChanged((x, y) => x.id === y.id),
        ),
        this.messaging.requestToken.pipe(filter(isNotNull), distinctUntilChanged()),
      ]).subscribe(async ([_, device_token]) => {
        console.log('FCM registration token:', device_token);
        let failFlag = true;
        while (failFlag) {
          await axios
            .post(
              environment.api_base_url + 'pharmacist/device_tokens',
              { os: 'web', device_token },
              {
                headers: {
                  Authorization: await this.cognito.getAccessToken().then(t => t.getJwtToken()),
                  'Content-Type': 'application/json',
                },
              },
            )
            .then(() => { failFlag = false; })
            .catch(error => console.log('POST FCM device token failed:\n', error));
          // 失敗した場合、1分ごとにデバイストークンの登録をやり直す
          await new Promise(resolve => setTimeout(resolve, 60000));
        }
      });
      this.messaging.onMessage(payload => {
        console.log('message received.', payload);
        const notifications = this.getReceivedNotification();
        notifications.push(JSON.parse(payload.data['pinpoint.jsonBody']));
        this.setReceivedNotification(notifications);
        this._messageReceive.next(null);
        new Notification(payload.data['pinpoint.notification.title'], {
          body: payload.data['pinpoint.notification.body'],
          icon: './assets/logo_sq.png',
        }).onclick = _ => {
          window.focus();
        };
      });
    });

    /*
    const notificationTimer = setInterval(async () => {
      console.log('timer invoked.');
      if (this.pharmacy !== null) {
        await axios
          .get<any>(
            environment.api_base_url + `pharmacist/pharmacies/${this.pharmacy.id}/notification`,
            {
              headers: {
                Authorization: await this.cognito.getAccessToken().then(t => t.getJwtToken()),
                'Content-Type': 'application/json',
              },
            },
          )
          .then((ret) => {
            console.log('timer invoked.', ret);
            let notifications: any[] = this.getReceivedNotification();
            for (let sn of ret.data) {
              const val = notifications.find((cn) => {
                return cn.id == sn.id;
              });
              if (val == undefined) {
                console.log('notification does not exist.');
                new Notification(sn.data.title, {
                  body: sn.data.body,
                  icon: './assets/logo_sq.png',
                }).onclick = _ => {
                  window.focus();
                };
                notifications.push(sn);
              }
            }
            // 15分経った通知は削除
            const cuttime: number = Date.now() - 15 * 60 * 1000;
            const newNotification = notifications.filter((cn) => {
              return parseInt(cn.id.substring(0, 16), 10) > cuttime;
            });
            this.setReceivedNotification(newNotification);
          })
          .catch(error => console.log('get notification failed:\n', error));
      }
    }, 300000);
    */

    const untreatedTimer = setInterval(async () => {
      console.log('untreated timer invoked.');
      if (this.pharmacy !== null) {
        await axios
          .get<any>(
            environment.api_base_url + `pharmacist/pharmacies/${this.pharmacy.id}/untreated`,
            {
              headers: {
                Authorization: await this.cognito.getAccessToken().then(t => t.getJwtToken()),
                'Content-Type': 'application/json',
              },
            },
          )
          .then((ret) => {
            for (let sn of ret.data) {
              new Notification(sn.title, {
                body: sn.context,
                icon: './assets/logo_sq.png',
              }).onclick = _ => {
                window.focus();
              };
            }
          })
          .catch(error => console.log('get untreated failed:\n', error));
      }
    }, 300000);

  }

  get messageReceive() {
    return this._messageReceive.asObservable();
  }

  private getReceivedNotification() {
    let str: string | null = localStorage.getItem('notification');
    let notifications: any[] = [];
    if (str !== null) {
      try {
        notifications = JSON.parse(str);
      } catch (error) {
        notifications = [];
      }
    }
    return notifications;
  }

  private setReceivedNotification(notifications: any[]) {
    localStorage.setItem('notification', JSON.stringify(notifications));
  }
}
