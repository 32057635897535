import { trigger, animate, style, transition } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, HostBinding, Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { InstructionStatus } from 'src/models/pci';

type tooltipData = {
  start: Date | null;
  end: Date | null;
  title: string;
  backgroundColor: string;
  id: string;
  selected: boolean;
  instructionStatus: InstructionStatus;
};
export const TOOLTIP_DATA = new InjectionToken<tooltipData[]>('TOOLTIP_DATA');

@Component({
  selector: 'app-hover-tooltip',
  templateUrl: './hover-tooltip.component.html',
  styleUrls: ['./hover-tooltip.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('.3s .25s ease-in-out', style({ opacity: 1 }))]),
    ]),
  ],
})
export class HoverTooltipComponent {
  @HostBinding('@fadeInAnimation') animation = '';
  constructor(
    @Inject(TOOLTIP_DATA)
    public readonly data: tooltipData[],
  ) {}

  public dateToString(start: Date | null, end: Date | null) {
    let str = '';
    if (!start) {
      return str;
    }
    str += `${start.toLocaleDateString()} ${start
      .getHours()
      .toString()
      .padStart(2, '0')}:${start
      .getMinutes()
      .toString()
      .padStart(2, '0')}~`;
    if (!end) {
      return str;
    }
    str += `${end
      .getHours()
      .toString()
      .padStart(2, '0')}:${end
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
    return str;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  constructor(private readonly parentInjector: Injector, private readonly overlay: Overlay) {}
  createTooltip(positionX: number, positionY: number, data: tooltipData[]): OverlayRef {
    const positionStrategy = this.overlay
      .position()
      .global()
      .top(positionY + 'px')
      .left(positionX + 'px');
    const tooltipConfig = {
      positionStrategy,
      width: 'auto',
      height: 'auto',
    };
    const injector = Injector.create({
      parent: this.parentInjector,
      providers: [{ provide: TOOLTIP_DATA, useValue: data }],
    });
    const ref = this.overlay.create(tooltipConfig);
    ref.attach(new ComponentPortal(HoverTooltipComponent, null, injector));
    return ref;
  }
}
