<audio style="display: none" id="meeting-audio"></audio>
<div class="detail">
  <table mat-table [dataSource]="pci ? [pci] : []">
    <ng-container matColumnDef="timeText">
      <th mat-header-cell *matHeaderCellDef>開始日時</th>
      <td mat-cell *matCellDef="let element">{{ element.reservation }}</td>
    </ng-container>

    <ng-container matColumnDef="patientName">
      <th mat-header-cell *matHeaderCellDef>患者名</th>
      <td mat-cell *matCellDef="let element">{{ element.patientName }}</td>
    </ng-container>

    <ng-container matColumnDef="pharmacistName">
      <th mat-header-cell *matHeaderCellDef>薬剤師名</th>
      <td mat-cell *matCellDef="let element">{{ element.pharmacistName }}</td>
    </ng-container>

    <ng-container matColumnDef="wasConfirmed">
      <th mat-header-cell *matHeaderCellDef>予約確認</th>
      <td mat-cell *matCellDef="let element">{{ element.status | reservation_status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<mat-accordion fxLayoutGap="15px">
  <app-patient-info-accordion [patientInfo]="patientInfo" [isExpanded]="false"></app-patient-info-accordion>
</mat-accordion>
<div class="buttons-bar" fxLayout="row" fxLayoutAlign="space-between">
  <div *ngIf="!isMeetingStarted">
    <button
      class="round-button"
      mat-raised-button
      *ngIf="isPerformed"
      (click)="endMeetingFinaly('pharmacist')"
      aria-label="end meeting"
      color="warn"
    >
      <mat-icon>cancel</mat-icon>
      服薬指導を終了する
    </button>
    <button
      class="round-button"
      mat-raised-button
      *ngIf="isPerformed"
      (click)="incompleteMeeting()"
      aria-label="incomplete meeting"
      color="warn"
    >
      <mat-icon>cancel</mat-icon>
      服薬指導の実施を中止する
    </button>
    <button
      class="round-button"
      mat-raised-button
      (click)="savePciQa()"
      [disabled]="saveQa"
      aria-label="incomplete meeting"
      color="primary"
    >
      <mat-icon>save</mat-icon>
      服薬指導QAを保存する
    </button>
    <button
      class="round-button"
      mat-raised-button
      *ngIf="isConfirmed"
      [disabled]="isCalling"
      (click)="startMeeting()"
      aria-label="start meeting"
      color="primary"
    >
      <mat-icon>call</mat-icon>
      呼び出す
    </button>
    <button
      class="round-button"
      mat-raised-button
      *ngIf="isPerformed"
      (click)="startMeeting()"
      aria-label="start meeting"
      color="primary"
    >
      <mat-icon>call</mat-icon>
      再呼び出し
    </button>

    <button
      class="round-button"
      *ngIf="isMeetingOver"
      mat-raised-button
      color="warn"
      fxFlexOffset="10px"
      (click)="complete()"
    >
      <mat-icon>phonelink_off</mat-icon>
      服薬指導を完了
    </button>
  </div>
  <div *ngIf="isMeetingStarted">
    <button
      class="round-button"
      mat-raised-button
      (click)="savePciQa()"
      aria-label="incomplete meeting"
      color="primary"
    >
      <mat-icon>save</mat-icon>
      服薬指導QAを保存する
    </button>
    <button
      class="round-button"
      mat-raised-button
      *ngIf="isCancelled"
      (click)="stopCall()"
      aria-label="cancel meeting"
      color="warn"
    >
      <mat-icon>call_end</mat-icon>
      呼び出しを終了する
    </button>
    <button
      class="round-button"
      mat-raised-button
      *ngIf="!isCancelled"
      (click)="endMeeting('pharmacist')"
      aria-label="end meeting"
      color="warn"
    >
      <mat-icon>phonelink_off</mat-icon>
      終了する
    </button>
  </div>
</div>
<div *ngIf="isMeetingStarted" fxLayout="row" class="video-audio-selection-area">
  <mat-form-field fxFlex>
    <mat-label>カメラ</mat-label>
    <select matNativeControl required [(ngModel)]="videoSelection" (ngModelChange)="selectVideoInputDevice()">
      <option *ngFor="let device of videoInputDevices; index as i" [value]="i">{{ device.label }}</option>
    </select>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>音声入力</mat-label>
    <select matNativeControl required [(ngModel)]="inputSelection" (ngModelChange)="selectAudioInputDevice()">
      <option *ngFor="let device of audioInputDevices; index as i" [value]="i">{{ device.label }}</option>
    </select>
  </mat-form-field>
  <mat-form-field fxFlex>
    <mat-label>音声出力</mat-label>
    <select matNativeControl required [(ngModel)]="outputSelection" (ngModelChange)="selectAudioOutputDevice()">
      <option *ngFor="let device of audioOutputDevices; index as i" [value]="i">{{ device.label }}</option>
    </select>
  </mat-form-field>
</div>
<div fxLayout="row" class="pci-wrapper">
  <div fxFlex="30" fxLayout="row" class="video-chat-wrapper">
    <div fxFlex="100" class="video-area1">
      <video id="video-patient"></video>
    </div>
  </div>
  <div fxFlex="30" fxLayout="column" class="text-chat-wrapper">
    <div fxFlex="30" class="video-area2">
      <video id="video-pharmacist"></video>
    </div>
    <div fxFlex="7" class="button-area">
      <div class="buttons-bar2" fxLayout="row" fxLayoutAlign="space-between">
        <div>
          <button mat-mini-fab (click)="toggleMute()" aria-label="Mute toggle" [color]="muted ? 'warn' : 'primary'">
            <mat-icon class="muted">{{ muted ? 'mic_off' : 'mic' }}</mat-icon>
          </button>
          <button
            mat-mini-fab
            (click)="toggleCamera()"
            aria-label="Camera toggle"
            [color]="!useCamera ? 'warn' : 'primary'"
          >
            <mat-icon class="video">{{ useCamera ? 'videocam' : 'videocam_off' }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <chat fxFlex="70" class="chat-area" [posts]="posts" (sendMessageEvent)="sendMessage($event)"></chat>
  </div>

  <div fxFlex="40" class="template-area">
    <qa-template (modelEvent)="qaAnswer = $event"></qa-template>
  </div>
</div>
<div class="center" *ngIf="isCalling">
  <mat-spinner diameter="100" color="primary"> </mat-spinner>
  <div class="calling">
    <span>呼び出し中です....</span>
  </div>
</div>
