export const environment = {
  production: false,
  domain: 'localhost:4200',
  region: 'ap-northeast-1',
  api_base_url: 'https://test.apisec.cncon.jp/v1/',
  chime_url: 'https://test.apisec.cncon.jp/v1/chime/',
  userPoolIdForPharmacy: 'ap-northeast-1_jm9rKV6GI',
  clientIdForPharmacy: '6medscm3q710thpje5rp5f0d2g',
  userPoolIdForPharmacist: 'ap-northeast-1_wo3TJLB1G',
  clientIdForPharmacist: 'e9rdidaj3f898jcmblnm6pfsa',
  title: 'オンライン服薬指導システム（C.Medical 検証環境）',
  windowTitle: 'Connect Online (C.Medical 検証環境)',
  firebase: {
    apiKey: 'AIzaSyA29VTv4hg3KE6Cx5XWq1CtZrL1-CyyOwQ',
    authDomain: 'connect-online-9d05e.firebaseapp.com',
    projectId: 'connect-online-9d05e',
    storageBucket: 'connect-online-9d05e.appspot.com',
    messagingSenderId: '187779671788',
    appId: '1:187779671788:web:46767bd7b62dec9ab027c9',
    measurementId: 'G-S9KEHNKL4V',
  },
};
