<div class="root" fxLayout="column" fxLayoutGap="20px">
  <div class="follow-edit-part">
    <div
      class="question-container"
      cdkDropList
      [cdkDropListDisabled]="disabled"
      #questionList
      (cdkDropListDropped)="dropQuestion($event)"
      fxLayout="column"
      fxLayoutGap="15px"
      fxFlexAlign="center"
    >
      <mat-card class="question-content" cdkDrag *ngFor="let question of questions; index as i" fxLayout="column">
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-icon fxFlexAlign="stretch" cdkDragHandle [class.not-draggable]="disabled" class="drag-handle">
            drag_indicator
          </mat-icon>
          <div fxLayout="row" fxLayoutGap="10px" fxFlex>
            <div fxLayout="column" fxLayoutAlign="space-evenly" class="type-and-require-select">
              <mat-checkbox
                *ngIf="isVisibleRequiredCheckBox"
                [ngModel]="question.required"
                (ngModelChange)="toggleRequirement(i, $event)"
                [disabled]="disabled"
              >
                必須
              </mat-checkbox>
              <mat-radio-group
                class="type-toggle"
                fxLayout="column"
                [ngModel]="question.type"
                (ngModelChange)="toggleQuestionType(i, $event)"
                [disabled]="disabled"
              >
                <mat-radio-button [value]="'radio'">1つ選択</mat-radio-button>
                <mat-radio-button [value]="'checkbox'">複数選択</mat-radio-button>
                <mat-radio-button [value]="'textbox'">自由記述</mat-radio-button>
              </mat-radio-group>
            </div>
            <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px">
              <mat-form-field
                class="form-field question"
                fxFlex.gt-sm="45"
                fxFlexAlign.gt-sm="stretch"
                fxLayout="column"
                fxLayoutAlign="center"
              >
                <mat-label>質問文を入力</mat-label>
                <textarea
                  class="textarea question"
                  matInput
                  [ngModel]="question.question"
                  (ngModelChange)="question.question = $event; questionsChange.emit(questions)"
                  [disabled]="disabled"
                ></textarea>
              </mat-form-field>
              <div class="choice-area-wrap" fxFlex.gt-sm="55">
                <div class="choice-area" fxLayout="column" fxLayoutGap="10px">
                  <div
                    class="choice-container"
                    cdkDropList
                    [cdkDropListDisabled]="disabled || question.type === 'textbox'"
                    (cdkDropListDropped)="dropChoice(i, $event)"
                  >
                    <mat-card
                      class="choice-content"
                      fxLayout="row"
                      fxLayoutGap="10px"
                      cdkDrag
                      [class.not-draggable]="disabled || question.type === 'textbox'"
                      *ngFor="let choice of question.answers; index as j; trackBy: trackByIndex"
                    >
                      <div fxFlexAlign="stretch" fxLayout="row" fxLayoutAlign="center" class="choice-number-wrap">
                        <span class="choice-number">{{ j + 1 }}.</span>
                      </div>
                      <mat-form-field fxFlex>
                        <mat-label>選択肢名を入力</mat-label>
                        <input
                          [id]="'choice-' + i + '-' + j"
                          matInput
                          type="text"
                          [ngModel]="question.answers[j]"
                          (ngModelChange)="question.answers[j] = $event; questionsChange.emit(questions)"
                          [disabled]="disabled || question.type === 'textbox'"
                          (keydown.enter)="enter(i, j)"
                        />
                      </mat-form-field>
                      <button
                        mat-icon-button
                        fxFlexAlign="center"
                        [disabled]="
                          (question.answers.length < 2 && question.type !== 'textbox') ||
                          disabled ||
                          question.type === 'textbox'
                        "
                        (click)="question.answers.splice(j, 1); questionsChange.emit(questions)"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-card>
                  </div>
                  <button
                    mat-stroked-button
                    (click)="addChoice(i)"
                    class="add-choice"
                    [class.disabled-button]="disabled || question.type === 'textbox'"
                    fxFlexAlign="center"
                    [disabled]="disabled || question.type === 'textbox'"
                  >
                    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                      <mat-icon fxFlexAlign="center">add_circle_outline</mat-icon>
                      <div fxFlexAlign="center">選択肢を追加</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            mat-icon-button
            fxFlexAlign="start"
            (click)="questions.splice(i, 1); questionsChange.emit(questions)"
            [disabled]="disabled"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-card>
      <div *ngIf="questions.length === 0">質問がありません</div>
    </div>
  </div>
  <button
    mat-stroked-button
    fxFlexAlign="center"
    (click)="addQuestion()"
    [class.disabled-button]="disabled"
    [disabled]="disabled"
    class="add-question"
  >
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-icon fxFlexAlign="center">add_circle_outline</mat-icon>
      <div fxFlexAlign="center">質問を追加</div>
    </div>
  </button>
</div>
