import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { DispensingStatus } from 'src/models/dispensing-status';

@Pipe({ name: 'dispensing_status' })
export class DispensingStatusPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === DispensingStatus.received,
        _ => '受信済み',
      )
      .on(
        v => v === DispensingStatus.confirmed,
        _ => '確認済み',
      )
      .on(
        v => v === DispensingStatus.connectedToHistory,
        _ => '薬歴連携済み',
      )
      .on(
        v => v === DispensingStatus.dispensed,
        _ => '調剤済み',
      )
      .on(
        v => v === DispensingStatus.informedToPatient,
        _ => '患者通知済み',
      )
      .on(
        v => v === DispensingStatus.receivedByPatient,
        _ => '患者受領済み',
      )
      .otherwise(_ => '不明');
  }
}
