import { Pipe, PipeTransform } from '@angular/core';
import { when } from 'src/app/modules/when';
import { PrescriptionStatus as Status } from 'src/models/prescription';

@Pipe({ name: 'prescription_status' })
export class PrescriptionStatusPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    return when(value)
      .on(
        v => v === Status.create_new,
        () => '新規',
      )
      .on(
        v => v === Status.sent,
        () => '未確認',
      )
      .on(
        v => v === Status.confirmed,
        () => '受領済み',
      )
      .on(
        v => v === Status.dispensed,
        () => '調剤完了',
      )
      .on(
        v => v === Status.req_resend,
        () => '再送通知済み',
      )
      .on(
        v => v === Status.rejected,
        () => '拒否済み',
      )
      .on(
        v => v === Status.completed,
        () => '交付済み',
      )
      .on(
        v => v === Status.conf_cancelled,
        () => 'キャンセル(確認)',
      )
      .on(
        v => v === Status.nconf_cancelled,
        () => 'キャンセル(未確認)',
      )
      .otherwise(() => '不明');
  }
}
