<div fxLayout.gt-md="row" fxLayout="column" fxLayoutGap="30px">
  <div fxFlex.gt-md="50">
    <div class="title">服薬フォローテンプレートを利用</div>
    <div class="list-container">
      <mat-accordion class="list">
        <div fxLayout="row" class="reload-wrap">
          <button fxFlexAlign="center" mat-icon-button [disabled]="templatesFetching" (click)="templatesReloadEvent()">
            <mat-icon>cached</mat-icon>
          </button>
        </div>
        <mat-card class="template-header list-header" fxLayout="row">
          <div fxFlex="35">グループ名</div>
          <div fxFlex="40">テンプレート名</div>
          <div fxFlex="25">質問数</div>
        </mat-card>
        <ng-container *ngFor="let group of templateGroupNames">
          <ng-container *ngFor="let template of templateGroups[group]">
            <mat-expansion-panel class="follow-expansion-panel">
              <mat-expansion-panel-header class="follow-panel-header" fxLayout="row">
                <div fxFlex="35">{{ group }}</div>
                <div fxFlex="40">{{ template.name }}</div>
                <div fxFlex="25" class="number-of-questions">{{ template.qa ? template.qa.length : 0 }}</div>
              </mat-expansion-panel-header>
              <div class="question-container" fxLayout="column" fxLayoutGap="10px">
                <div class="question-content" *ngFor="let question of template.qa" fxLayout="column" fxLayoutGap="5px">
                  <mat-chip-list class="chip-list">
                    <mat-chip *ngIf="question.required" class="required chip">必須</mat-chip>
                    <mat-chip *ngIf="question.type === 'radio'" class="chip">1つ選択</mat-chip>
                    <mat-chip *ngIf="question.type === 'checkbox'" class="chip">複数選択</mat-chip>
                    <mat-chip *ngIf="question.type === 'textbox'" class="chip">自由記述</mat-chip>
                  </mat-chip-list>
                  <mat-divider class="divider"></mat-divider>
                  <div>{{ question.question ? question.question : '(質問文なし)' }}</div>
                  <div fxLayout="column" *ngIf="question.type !== 'textbox'">
                    <div *ngFor="let choice of question.answers">・{{ choice ? choice : '(選択肢名なし)' }}</div>
                  </div>
                </div>
              </div>
              <mat-action-row>
                <button
                  mat-button
                  color="primary"
                  routerLink="../new"
                  [queryParams]="{ templateId: template.id, patientInfoId: patientInfoId }"
                >
                  このテンプレートを使用
                </button>
              </mat-action-row>
            </mat-expansion-panel>
          </ng-container>
        </ng-container>
      </mat-accordion>
      <div class="loading-shade" *ngIf="templatesFetching">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
  <div fxFlex.gt-md="50">
    <div class="title">過去の服薬フォローを利用</div>
    <div class="list-container">
      <mat-accordion class="past-follow-list list">
        <app-custom-paginator
          class="paginator"
          [disabled]="followsFetching"
          [length]="followsTotalRecords"
          [pageSize]="followsPageSize"
          [pageIndex]="followsPageNumber"
          (page)="followsPageEvent($event)"
          (reload)="followsReloadEvent()"
        ></app-custom-paginator>
        <mat-card class="past-follow-header list-header" fxLayout="row">
          <div fxFlex="40">送信日時</div>
          <div fxFlex="40">患者氏名</div>
          <div fxFlex="20">質問数</div>
        </mat-card>
        <mat-expansion-panel class="follow-expansion-panel" *ngFor="let follow of follows">
          <mat-expansion-panel-header class="follow-panel-header" fxLayout="row">
            <div fxFlex="40">
              {{
                follow.send_at !== null && follow.send_at !== undefined
                  ? (follow.send_at | date: 'yyyy/MM/dd HH:mm:ss')
                  : ''
              }}
            </div>
            <div fxFlex="40">{{ getPatientName(follow.patient_info) }}</div>
            <div fxFlex="20" class="number-of-questions">
              {{ follow.pcf_questions ? follow.pcf_questions.length : 0 }}
            </div>
          </mat-expansion-panel-header>
          <div class="question-container" fxLayout="column" fxLayoutGap="10px">
            <div
              class="question-content"
              *ngFor="let question of follow.pcf_questions"
              fxLayout="column"
              fxLayoutGap="5px"
            >
              <mat-chip-list class="chip-list">
                <mat-chip *ngIf="question.required" class="required chip">必須</mat-chip>
                <mat-chip *ngIf="question.type === 'radio'" class="chip">1つ選択</mat-chip>
                <mat-chip *ngIf="question.type === 'checkbox'" class="chip">複数選択</mat-chip>
                <mat-chip *ngIf="question.type === 'textbox'" class="chip">自由記述</mat-chip>
              </mat-chip-list>
              <mat-divider class="divider"></mat-divider>
              <div>{{ question.question ? question.question : '(質問文なし)' }}</div>
              <div fxLayout="column" *ngIf="question.type !== 'textbox'">
                <div *ngFor="let choice of question.answers">・{{ choice ? choice : '(選択肢名なし)' }}</div>
              </div>
            </div>
          </div>
          <mat-action-row>
            <button
              mat-button
              color="primary"
              routerLink="../new"
              [queryParams]="{ followId: follow.id, patientInfoId: patientInfoId }"
            >
              この服薬フォローを再利用
            </button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="loading-shade" *ngIf="followsFetching">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</div>
