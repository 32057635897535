import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { polyfill } from 'smoothscroll-polyfill';

export type Post = {
  message: string;
  isSelf: boolean;
};
@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  @Input() posts: Post[] = [];
  @Output() sendMessageEvent = new EventEmitter<string>();
  text = '';

  constructor() {}
  ngOnInit(): void {}
  
  sendMessage(event: any): void {
    this.text = event.target.value
    if (this.text.trim()) {
      if (event.keyCode === 13) {
        this.sendMessageEvent.emit(this.text);
        this.text = '';
      }
    } else {
      this.text = '';
    }
  }

  sendMessage2(event: any): void {
    if (this.text.trim()) {
        this.sendMessageEvent.emit(this.text);
        this.text = '';
    } else {
      this.text = '';
    }
  }

  scroll() {
    polyfill();
    window.setTimeout(() => {
      const element = document.getElementById(`chat-${this.posts.length - 1}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 100);
  }
}
